<template>
  <div class="container-fluid">
    <h1>Функционал личного кабинета</h1>
    <opportunities-list />
    <opportunities-start />
  </div>
</template>

<script>
import OpportunitiesList from "./OpportunitiesList";
import OpportunitiesStart from "./OpportunitiesStart";

export default {
  components: { OpportunitiesStart, OpportunitiesList },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.container-fluid {
  padding: 140px 140px 160px 140px;
}

h1 {
  font-size: 36px;
  font-weight: 600;
  text-align: center;
}

@media (max-width: 1000px) {
  .container-fluid {
    padding: 60px 15px 90px 15px;
  }
  h1 {
    font-size: 30px;
  }
}
</style>
