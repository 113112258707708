import { render, staticRenderFns } from "./TariffDiscount.vue?vue&type=template&id=584f8139&scoped=true&"
import script from "./TariffDiscount.vue?vue&type=script&lang=js&"
export * from "./TariffDiscount.vue?vue&type=script&lang=js&"
import style0 from "./TariffDiscount.vue?vue&type=style&index=0&id=584f8139&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "584f8139",
  null
  
)

export default component.exports