<template>
  <div class="function-list-item">
    <img src="@/assets/okaygreen.svg" alt="okay-icon">
    <span>{{ baseFunction }}</span>
  </div>
</template>

<script>
export default {
  props: ['baseFunction']
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.function-list-item {
  width: 33.33%;
  height: 25%;
  display: flex;
  align-items: center;
  padding-right: 20px;

  span {
    font-family: 'Montserrat';
    line-height: 150%;
    color: $white-color;
    font-size: 12px;
    font-weight: 500;
  }

  img {
    width: 8px;
    height: 6px;
    margin-right: 5px;
  }
}

@media (max-width: 1000px) {
  .function-list-item {
    width: 100%;
    padding-right: 0;
    justify-content: center;
    margin-top: 10px;

    span {
      font-size: 13px;
    }
  }
}
</style>