<template>
  <a class="features-item">
    <div class="img-container">
      <img :src="item.img" alt="feature-image">
    </div>
    <span class="features-item__description">
      {{ item.description }}
    </span>
  </a>
</template>

<script>
export default {
  props: ['item']
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.features-item {
  width: 33.33%;
  padding: 0 20px;
  //cursor: pointer;
  border-radius: 5px;
  position: relative;

  &:nth-child(4) {
    .features-item__description {
      width: 40%;
    }
  }

  &:nth-child(n+4):nth-child(-n+9) {
    margin-top: 40px;
  }

  &:last-child {
    .features-item__description {
      color: $purple-color;
    }
  }
}

.img-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  border-radius: 5px;
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  img {
    width: 100%;
    height: 100%;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}

.features-item__description {
  width: 60%;
  position: absolute;
  top: 20px;
  left: 45px;
  font-size: 21px;
  font-weight: 600;
  color: $white-color;
}

</style>