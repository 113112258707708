<template>
  <div class="opportunities-item">
    <div class="item-text">
      <h1>{{ item.header }}</h1>
      <div v-if="item.description" class="item-description">
          <span v-for="(description, index) in item.description" :key="index">
            {{ description }}
          </span>
      </div>
      <div v-if="item.info" class="item-info">
          <span v-if="item.infoHeader" class="item-info-header">
            {{ item.infoHeader }}
          </span>
        <div v-for="(info, index) in item.info" :key="index" class="item-info-block">
          <svg class="arrow-icon">
            <use href="@/assets/arrow.svg#arrow-icon"></use>
          </svg>
          <span>{{ info }}</span>
        </div>
      </div>
      <div v-if="item.features" class="item-features">
          <span v-if="item.featuresHeader" class="features-header">
            {{ item.featuresHeader }}
          </span>
        <div v-for="(feature, index) in item.features" :key="index" class="item-feature">
          <img src="@/assets/okay-icon-circle.svg" alt="okay-icon">
          <span>{{ feature }}</span>
        </div>
      </div>
      <div v-if="item.socials" class="item-socials">
          <span class="item-socials-header">
            {{ item.socialsHeader }}
          </span>
        <div class="item-socials-container">
          <div v-for="(social, index) in item.socials" :key="index" class="item-socials-block">
            <img v-if="social.img" :src="social.img" alt="social-icon">
            <span>{{ social.name }}</span>
          </div>
        </div>
      </div>
      <div v-if="item.counts" class="item-counts">
          <span v-if="item.headerCounts" class="item-counts-header">
            {{ item.headerCounts }}
          </span>
        <div v-if="item.counts" class="item-counts-container">
          <div v-for="count in item.counts" :key="count.id" class="item-count-block">
            <div class="item-count-block__num">
              {{ count.id }}
            </div>
            <span>
                {{ count.description.boldText }}
              <span>{{ count.description.normalText }}</span>
            </span>
          </div>
        </div>
        <span v-if="item.footerCounts" class="item-counts-footer">
            {{ item.footerCounts }}
          </span>
      </div>
    </div>
    <img v-if="item.img" class="item-img" :src="isMobileVersion ? item.img.mobile : item.img.desktop" alt="item-img">
  </div>
</template>

<script>
export default {
  props: ['item'],
  data: () => ({
    width: 0,
    isMobileVersion: false
  }),
  created() {
    window.addEventListener("resize", this.updateWidth);
    this.updateWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.updateWidth);
  },
  methods: {
    updateWidth() {
      this.width = window.innerWidth;
      this.width > 1000 ? (this.isMobileVersion = false) : (this.isMobileVersion = true);
    }
  }

}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.opportunities-item {
  padding: 60px 50px;
  background: #F0F4FF;
  border-radius: 20px;
  margin-top: 50px;
  display: flex;
  position: relative;
  height: 440px;

  &:nth-child(2) {
    padding: 50px;
  }

  &:nth-child(3) {
    padding: 70px 50px;
  }

  &:nth-child(even) {
    flex-direction: row-reverse;

    .item-text {
      padding-right: 0;
    }

    .item-img {
      left: 0;
      right: unset;
    }
  }

  &:nth-child(4) {
    .item-features {
      .item-feature {
        &:first-child {
          margin-top: 25px;
        }

        margin-top: 15px;
      }
    }
  }

  &:nth-child(5) {
    padding: 40px 50px;

    .features-header {
      font-size: 16px;
    }

    .item-features {
      .item-feature {
        margin-top: 15px;
      }
    }

  }

  &:nth-child(7) {
    padding: 65px 50px;

    .item-description {
      span {
        margin-top: 25px;
      }
    }
  }

  &:nth-child(8) {
    padding: 40px 50px;
  }

  &:nth-child(9) {
    padding: 65px 50px;

    .item-description {
      span {
        font-size: 20px;
        margin-top: 10px;
      }
    }

    .features-header {
      font-size: 16px;
    }

    .item-features {
      .item-feature {
        margin-top: 10px;
      }
    }
  }

  &:last-child {
    padding: 90px 50px;
  }
}

.item-text {
  width: 50%;

  .features-header {
    font-size: 20px;
    font-weight: 600;
    margin-top: 25px;
  }

  .item-features {
    display: flex;
    flex-direction: column;

    .item-feature {
      margin-top: 25px;
      display: flex;
      align-items: flex-start;

      span {
        font-size: 16px;
      }

      img {
        width: 14px;
        height: 14px;
        margin-top: 5px;
        margin-right: 5px;
      }
    }
  }
}

.item-img {
  height: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
}

h1 {
  font-size: 30px;
  font-weight: 600;
}

.item-description {
  display: flex;
  flex-direction: column;

  span {
    margin-top: 15px;
  }
}

.item-info {
  .item-info-header {
    font-weight: 600;
    margin-top: 10px;
    display: block;
  }

  .item-info-block {
    margin-top: 5px;

    .arrow-icon {
      width: 12px;
      height: 8px;
      margin-right: 5px;

      use {
        color: $main-color;
      }
    }
  }
}

.item-socials {
  margin-top: 20px;

  .item-socials-header {
    font-weight: 600;
  }

  .item-socials-container {
    display: flex;
    flex-wrap: wrap;

    .item-socials-block {
      margin-top: 15px;
      width: 33.33%;
      display: flex;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
  }
}

.item-counts {
  margin-top: 20px;

  .item-counts-header {
    font-weight: 600;
  }

  .item-counts-container {
    display: flex;
    flex-direction: column;

    .item-count-block {
      display: flex;

      span {
        font-weight: 600;

        span {
          font-weight: 400;
        }
      }

      &:first-child {
        margin-top: 20px;
      }

      margin-top: 15px;

      .item-count-block__num {
        min-width: 20px;
        min-height: 20px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: $main-color;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        color: $white-color;
        margin-right: 10px;
      }
    }
  }

  .item-counts-footer {
    display: block;
    margin-top: 50px;
    font-size: 21px;
    font-weight: 600;
  }
}


@media (max-width: 1000px) {
  .opportunities-item {
    padding: 25px 20px 0 15px !important;
    margin-top: 25px;
    flex-direction: column;
    align-items: center;
    height: unset;

    .item-text {
      width: 100%;
      padding-right: 0;

      h1 {
        font-size: 24px;
      }
    }

    .item-img {
      width: 120%;
      position: unset;
    }

    &:nth-child(even) {
      flex-direction: column;

      .item-text {
        padding-right: 0;
        padding-left: 0;
      }

      .item-img {
        position: unset;
      }
    }

    &:first-child {
      margin-top: 35px;

      .item-features {
        .item-feature {
          margin-top: 20px;
        }
      }
    }

    &:nth-child(2) {
      .item-info {
        .item-info-header {
          margin-bottom: 10px;
        }
      }
    }

    &:nth-child(3) {
      .features-header {
        font-size: 16px;
        margin-top: 10px;
      }

      .item-feature {
        margin-top: 15px;
      }
    }

    &:nth-child(4) {
      .item-features {
        .item-feature {
          &:first-child {
            margin-top: 20px;
          }
        }
      }

    }

    &:nth-child(5) {
      .features-header {
        margin-top: 15px;
      }
    }

    &:nth-child(6) {
      .item-counts {
        margin-top: 15px;
      }

      .item-counts-footer {
        margin-top: 15px;
        font-size: 16px;
      }
    }

    &:nth-child(7) {
      h1 {
        width: 65%;
      }

      .item-description {
        span {
          margin-top: 15px;
        }
      }
    }

    &:nth-child(8) {
      .item-counts-container {
        .item-count-block {
          margin-top: 10px;
        }
      }
    }

    &:nth-child(9) {
      .item-description {
        span {
          margin-top: 15px;
          font-size: 16px;
        }
      }
    }
  }
  .item-socials {
    .item-socials-container {
      .item-socials-block {
        width: 50%;
      }
    }
  }
}
</style>