<template>
  <div @click.self="closeModal" class="modal-overlay">
    <div class="modal-content">
      <div class="modal-header">
        <img
          @click="closeModal"
          class="close-modal-icon"
          src="@/assets/chat-bot-screen/video-buttons/union.svg"
          alt="close-icon"
        />
      </div>
      <div class="modal-body">
        <h1>Ваша заявка принята</h1>
        <h2>Наши менеджеры скоро свяжутся с вами</h2>
        <a class="btn btn-blue" @click="closeModal"> Хорошо </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
}
.modal-content {
  width: 460px;
  min-height: 280px;
  margin: auto;
  border-radius: 15px;
  background: $white-color;
  position: relative;
  padding: 70px 40px 50px 40px;
}
.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.close-modal-icon {
  width: 14px;
  height: 14px;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  transition: 0.1;
  &:hover {
    transform: scale(1.3);
  }
}
h1 {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}
h2 {
  margin-top: 5px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}
.btn {
  margin-top: 25px;
  display: inline-block;
}
@media (max-width: 768px) {
  .modal-overlay {
    padding: 0 20px;
  }
}
</style>
