<template>
  <div class="container-fluid">
    <div class="features-content">
      <h1>Почему клиенты выбирают Dostavkinbot?</h1>
      <h2>
        За год работы к нашему сервису подключилось более 500 заведений. Мы
        обработали более 600.000 заказов и более 8 млн сообщений.
      </h2>
      <div class="features-item-list">
        <features-item
          v-for="item in featuresItems"
          :key="item.id"
          :item="item"
        />
      </div>
    </div>
    <div class="partners">
      <h3>Мы официальные партнеры:</h3>
      <div class="partners-list">
        <partners-item
          v-for="item in partnerItems"
          :key="item.id"
          :item="item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FeaturesItem from "./FeaturesItem";
import PartnersItem from "./PartnersItem";

export default {
  components: { PartnersItem, FeaturesItem },
  data: () => ({
    featuresItems: [
      {
        id: 1,
        name: "Быстрый старт",
        description:
          "Мы предлагаем готовое решение, которое внедряется меньше чем за день!",
        img: require("@/assets/main-screen/features/rocket.png"),
      },
      {
        id: 2,
        name: "Последние технологии по автоматизации",
        description:
          "Следим за технологическими трендами и внедряем новинки рынка на момент их появления. ",
        img: require("@/assets/main-screen/features/android.png"),
      },
      {
        id: 3,
        name: "Интеграции",
        description:
          "Ежемесячно расширяем интеграции. Доступны интеграции с CRM: Iiko, Мой склад, Poster, Frontpad, ЮКасса и многими другими",
        img: require("@/assets/main-screen/features/icons.png"),
      },
      {
        id: 4,
        name: "Поддержка 24/7",
        description:
          "Наша поддержка работает на благо клиентов и может решить любой возникающий вопрос удобным для клиента способом.",
        img: require("@/assets/main-screen/features/support.png"),
      },
    ],
    partnerItems: [
      {
        id: 1,
        img: require("@/assets/main-screen/partners/facebook.svg"),
      },
      {
        id: 2,
        img: require("@/assets/main-screen/partners/instagram.svg"),
      },
      {
        id: 3,
        img: require("@/assets/main-screen/partners/iiko.svg"),
      },
      {
        id: 4,
        img: require("@/assets/main-screen/partners/netologia.svg"),
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.container-fluid {
  padding: 80px 30px;
  background: linear-gradient(
      0deg,
      rgba(0, 145, 255, 0.4),
      rgba(0, 145, 255, 0.4)
    ),
    linear-gradient(93.45deg, #0091ff 9.65%, #16c774 96.55%);
}

.features-content {
  background: $white-blue-color;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 120px 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  width: 80%;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  line-height: 135%;
}

h2 {
  width: 70%;
  font-size: 21px;
  font-weight: 400;
  text-align: center;
  margin-top: 20px;
}

.features-item-list {
  display: flex;
  margin-top: 50px;
}

.partners {
  padding: 60px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h3 {
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $white-color;
  text-align: center;
}

.partners-list {
  margin-top: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 1000px) {
  .container-fluid {
    padding: 60px 10px;
  }
  .features-content {
    padding: 50px 5px;

    h1 {
      width: 100%;
      font-size: 24px;
      line-height: 140%;
    }

    h2 {
      width: 100%;
      font-size: 15px;
      line-height: 145%;
      padding: 0 25px;
    }
  }
  .features-item-list {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
  }
  .partners {
    padding: 60px 15px 0px 15px;

    h3 {
      letter-spacing: 0.05em;
    }
  }
  .partners-list {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}
</style>
