<template>
  <div class="container-fluid">
    <h1>Как запустить чат-бот для своего заведения?</h1>
    <div class="start-content">
      <div class="dashed-line"></div>
      <div class="start-content__step-list">
        <start-step v-for="step in steps" :key="step.id" :item="step" />
      </div>
    </div>
    <a
      href="https://panel.dostavkinbot.online/register"
      class="btn btn-main-gradient"
      >Начать сейчас</a
    >
  </div>
</template>

<script>
import StartStep from "./StartStep";

export default {
  components: { StartStep },
  data: () => ({
    steps: [
      {
        id: 1,
        header: "Зарегистрируйтесь и загрузите своё меню в личном кабинете, ",
        description:
          "синхронизировав личный кабинет с вашей CRM, либо через интерфейс личного кабинета",
        img: require("@/assets/chat-bot-screen/startInstruction/step1.png"),
      },
      {
        id: 2,
        header: "Выберите каналы продаж и получите готовые решения,",
        description:
          "для автоматического приема заказов в мессенджерах и соцсетях",
        img: require("@/assets/chat-bot-screen/startInstruction/step2.png"),
      },
      {
        id: 3,
        header: "Куда попадают все заказы из ваших чат-ботов?",
        description:
          "Обо всех новых заказах вам придет уведомление в личный кабинет, в чат-боты администраторов и в ваше CRM (если осуществлена синхронизизация)",
        img: require("@/assets/chat-bot-screen/startInstruction/step3.png"),
      },
    ],
    width: 0,
    isMobileVersion: false,
  }),
  created() {
    window.addEventListener("resize", this.updateWidth);
    this.updateWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.updateWidth);
  },
  methods: {
    updateWidth() {
      this.width = window.innerWidth;
      this.width > 1000
        ? (this.isMobileVersion = false)
        : (this.isMobileVersion = true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.container-fluid {
  padding: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  font-size: 36px;
  font-weight: 600;
  text-align: center;
}

.start-content {
  margin-top: 40px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.dashed-line {
  position: absolute;
  top: 60px;
  left: 50%;
  width: 1px;
  height: 66%;
  border: 1px dashed $green-color;
  transform: translate(-50%);
}

.start-content__steps-count {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 40px;
}

.btn-main-gradient {
  margin-top: 50px;
  display: inline-block;
}

@media (max-width: 1000px) {
  .container-fluid {
    padding: 60px 15px 100px 15px;
  }
  h1 {
    font-size: 24px;
  }
  .start-content {
    margin-top: 25px;
    padding-left: 60px;
  }
  .btn {
    margin-top: 40px;
  }
  .dashed-line {
    top: 40px;
    left: 20px;
    height: 64%;
  }
}
</style>
