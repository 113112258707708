<template>
  <div class="container-fluid">
    <Navbar v-if="isMobileVersion" />
    <div class="main-screen-content">
      <Navbar v-if="!isMobileVersion" />
      <div class="description">
        <div class="description__text">
          <h1>Сервис для запуска и прокачки доставки еды</h1>
          <h2>Для ресторанов с доставкой, сетей и Dark Kitchen</h2>
          <div class="description-features">
            <div
              v-for="(feature, index) in features"
              :key="index"
              class="description-features__item"
            >
              <svg class="arrow-icon">
                <use href="@/assets/arrow.svg#arrow-icon"></use>
              </svg>
              {{ feature }}
            </div>
          </div>
          <a
            href="https://panel.dostavkinbot.online/register"
            class="btn btn-main-gradient"
            >Начать сейчас</a
          >
        </div>
        <div class="description__img">
          <img
            v-if="isMobileVersion"
            src="@/assets/description-img-mobile.png"
            alt="description-image"
          />
          <img
            v-else
            src="@/assets/description-img.png"
            alt="description-image"
          />
        </div>
      </div>
      <img
        class="main-screen-points"
        src="@/assets/main-screen/mainScreenPoints.png"
        alt="points-bg"
      />
      <img
        class="ellipse main-screen-ellipse1"
        src="@/assets/main-screen/mainScreenEllipse1.png"
        alt="ellipse-bg"
      />
      <img
        class="ellipse main-screen-ellipse2"
        src="@/assets/main-screen/mainScreenEllipse2.png"
        alt="ellipse-bg"
      />
    </div>
  </div>
</template>

<script>
import Navbar from "../Navbar/Navbar";

export default {
  components: { Navbar },
  data: () => ({
    features: [
      "Готовые чат-боты и сайт для моментального оформления заказа",
      "Аналитика клиентов и их заказов",
      "Инструменты маркетинга для увеличения заказов и лояльности гостей",
    ],
    width: 0,
    isMobileVersion: false,
  }),
  created() {
    window.addEventListener("resize", this.updateWidth);
    this.updateWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.updateWidth);
  },
  methods: {
    updateWidth() {
      this.width = window.innerWidth;
      this.width > 1000
        ? (this.isMobileVersion = false)
        : (this.isMobileVersion = true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.container-fluid {
  padding: 20px 30px;
  height: 100vh;
}

.main-screen-content {
  padding: 30px 110px;
  height: 100%;
  border-radius: 20px;
  background: $main-screen-bg-color;
  position: relative;
}

.main-screen-points {
  width: 162px;
  position: absolute;
  right: 10px;
  bottom: 40px;
  z-index: 4;
}

.main-screen-ellipse1 {
  position: absolute;
  width: 416px;
  right: 180px;
  bottom: 0;
}

.main-screen-ellipse2 {
  position: absolute;
  width: 416px;
  right: 0;
  bottom: 0;
}

.description {
  display: flex;
  align-items: center;
  height: calc(100% - 30px);
}

.description__text {
  width: 50%;
}

.description__img {
  width: 50%;
  display: flex;
  align-items: center;

  img {
    z-index: 20;
    width: 100%;
  }
}

h1 {
  font-size: 46px;
  line-height: 120%;
}

h2 {
  font-size: 24px;
  line-height: 120%;
  font-weight: 600;
  margin-top: 30px;
}

.description-features {
  .description-features__item {
    font-size: 18px;
    line-height: 145%;
    align-items: center;

    svg {
      width: 14px;
      height: 10px;
      margin-right: 5px;

      use {
        color: $main-color;
      }
    }

    &:first-child {
      margin-top: 15px;
    }

    margin-top: 10px;
  }
}

.btn-main-gradient {
  margin-top: 50px;
  display: inline-block;
}

@media (min-width: 1000px) and (max-width: 1500px) {
  .description__img {
    img {
      width: 125%;
    }
  }
}

@media (max-width: 1000px) {
  .container-fluid {
    height: unset;
    padding: 15px;
    overflow: unset !important;
  }
  .main-screen-points,
  .ellipse {
    display: none;
  }
  .main-screen-content {
    margin-top: 15px;
    padding: 20px 20px;
  }
  .description {
    display: flex;
    flex-direction: column;

    .description__text {
      width: 100%;
      margin-top: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        text-align: center;
        font-size: 34px;
      }

      h2 {
        text-align: center;
        font-size: 20px;
        line-height: 150%;
      }

      .description-features__item {
        width: unset;
        text-align: center;
        font-size: 15px;
        margin-top: 10px;
      }

      .btn-main-gradient {
        margin-top: 20px;
        font-size: 18px;
      }
    }

    .description__img {
      margin-top: 35px;
      width: 100%;
      height: 200px;
      display: flex;
      justify-content: center;
      position: relative;

      img {
        max-width: 420px;
        max-height: 370px;
        position: absolute;
        top: 0;
        width: 120%;
      }
    }
  }
}
</style>
