<template>
  <div class="container-fluid">
    <h1>Воспользуйтесь всеми возможностями личного кабинета прямо сейчас!</h1>
    <a
      class="btn btn-main-gradient"
      href="https://panel.dostavkinbot.online/register"
      >Начать сейчас</a
    >
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.container-fluid {
  margin-top: 180px;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  width: 80%;
  font-size: 36px;
  font-weight: 600;
  text-align: center;
}

.btn {
  margin-top: 40px;
  display: inline-block;
}

@media (max-width: 1000px) {
  .container-fluid {
    margin-top: 80px;
  }
  h1 {
    width: 100%;
    font-size: 30px;
  }
}
</style>
