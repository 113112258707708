<template>
  <div class="container-fluid">
    <chat-bot-screen :startSlide="startSlide"/>
    <chat-bot-features/>
    <chat-bot-banner/>
    <chat-bot-start/>
    <Footer/>
  </div>
</template>

<script>
import ChatBotScreen from "../components/ChatBot/ChatBotScreen";
import ChatBotFeatures from "../components/ChatBot/Features/ChatBotFeatures";
import ChatBotBanner from "../components/ChatBot/Banner/Banner";
import ChatBotStart from "../components/ChatBot/StartInstruction/Start";
import Footer from "../components/Footer/Footer"

export default {
  props: ['startSlide'],
  name: 'chat-bot-view',
  components: {ChatBotStart, ChatBotBanner, ChatBotFeatures, ChatBotScreen, Footer},
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";


.container-fluid {
  overflow: hidden;
}
</style>