<template>
  <div class="container-fluid">
    <h1>Какие задачи решает Dostavkinbot для ресторана с доставкой?</h1>
    <swiper v-if="isMobileVersion" class="swiper" :options="swiperOption">
      <swiper-slide v-for="item in tasksItems" :key="item.id">
        <a href="">
          <img :src="item.img" alt="slide-image" />
          <span class="task-slide-description">{{ item.description }}</span>
        </a>
      </swiper-slide>
      <div
        class="swiper-pagination swiper-pagination-bullets"
        slot="pagination"
      ></div>
      <div class="swiper-button swiper-button-prev" slot="button-prev">
        <svg class="arrow-icon">
          <use href="@/assets/arrow.svg#arrow-icon"></use>
        </svg>
      </div>
      <div class="swiper-button swiper-button-next" slot="button-next">
        <svg class="arrow-icon">
          <use href="@/assets/arrow.svg#arrow-icon"></use>
        </svg>
      </div>
    </swiper>
    <div v-else class="tasks-list">
      <tasks-item v-for="item in tasksItems" :key="item.id" :item="item" />
    </div>
  </div>
</template>

<script>
import TasksItem from "./TasksItem";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  components: { TasksItem, Swiper, SwiperSlide },
  data: () => ({
    tasksItems: [
      {
        id: 1,
        description:
          "Прием заказов через удобные клиенту соцсети и мессенджеры",
        img: require("@/assets/main-screen/tasks/task1.jpg"),
      },
      {
        id: 2,
        description:
          "Повышение рейтинга заведения на открытых площадках (2GIS, Yandex, Google)",
        img: require("@/assets/main-screen/tasks/task2.jpg"),
      },
      {
        id: 3,
        description: "Удобно оформить заказ на вынос заранее",
        img: require("@/assets/main-screen/tasks/task3.jpg"),
      },
      {
        id: 4,
        description:
          "Не терять заказы в переписке и избежать человеческого фактора",
        img: require("@/assets/main-screen/tasks/task4.jpg"),
      },
      {
        id: 5,
        description: "Не платить +35% агрегаторам еды",
        img: require("@/assets/main-screen/tasks/task5.jpg"),
      },
      {
        id: 6,
        description: "Повысить ваше заведение в поисковой выдаче в интернете",
        img: require("@/assets/main-screen/tasks/task6.jpg"),
      },
      {
        id: 7,
        description: "Бесплатное оповещение клиентов об акциях и предложениях",
        img: require("@/assets/main-screen/tasks/task7.jpg"),
      },
      {
        id: 8,
        description:
          "Автоматическое и бесплатное оповещение о смене статуса заказа",
        img: require("@/assets/main-screen/tasks/task8.jpg"),
      },
      {
        id: 9,
        description:
          "Анализ эффективности рекламы и экономия рекламного бюджета",
        img: require("@/assets/main-screen/tasks/task9.jpg"),
      },
    ],
    swiperOption: {
      slidesPerView: 1,
      spaceBetween: 12,
      centeredSlides: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet(index, className) {
          return `<span class="${className} swiper-pagination-bullet-custom"></span>`;
        },
      },
    },
    width: 0,
    isMobileVersion: false,
  }),
  created() {
    window.addEventListener("resize", this.updateWidth);
    this.updateWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.updateWidth);
  },
  methods: {
    updateWidth() {
      this.width = window.innerWidth;
      this.width > 1000
        ? (this.isMobileVersion = false)
        : (this.isMobileVersion = true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.container-fluid {
  padding: 160px 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  width: 55%;
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  line-height: 135%;
}

.tasks-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 80px;
  margin-left: -20px;
  margin-right: -20px;
}

.swiper {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;

  .swiper-pagination {
    display: flex;
    justify-content: flex-start;
    padding-left: 20px;
    bottom: 22px;
  }
}

.swiper-button {
  width: 44px;
  height: 44px;
  background: transparent;
  border: 1px solid $main-color;
  border-radius: 50%;
  top: unset;
  bottom: 5px;

  use {
    color: $main-color;
  }

  &:hover {
    background: $main-color;

    use {
      color: $white-color;
    }
  }

  &.swiper-button-prev {
    right: 80px;
    left: unset;

    svg {
      transform: rotate(180deg);
    }
  }

  &.swiper-button-next {
    right: 20px;
  }

  svg {
    width: 14px;
    height: 12px;
  }
}

.swiper-slide {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;

  a {
    width: 100%;
  }

  img {
    width: 100%;
    border-radius: 10px;
  }

  span {
    position: absolute;
    top: 20px;
    left: 20px;
    color: $white-color;
    font-weight: 700;
  }
}

@media (max-width: 1000px) {
  .container-fluid {
    padding: 80px 0 100px 0;
  }
  .tasks-list {
    margin-right: 0;
    margin-left: 0;
  }
  h1 {
    width: 100%;
    font-size: 24px;
    padding: 0 15px;
    line-height: 140%;
  }
  .swiper {
    padding: 0 20px;
  }
  .task-slide-description {
    width: 70%;
    font-size: 20px;
  }
}
</style>
