<template>
  <div class="container-fluid">
    <navbar v-if="isMobileVersion" />
    <div class="personal-area-screen-content">
      <navbar v-if="!isMobileVersion" />
      <div class="description">
        <div class="description__text">
          <h1>Узнайте откуда пришел заказ и клиент</h1>
          <div class="description-features">
            <div
              v-for="(feature, index) in features"
              :key="index"
              class="description-features__item"
            >
              <svg class="arrow-icon">
                <use href="@/assets/arrow.svg#arrow-icon"></use>
              </svg>
              {{ feature }}
            </div>
            <div class="description-features__total">
              <img src="@/assets/okay-icon-circle.svg" alt="okay-icon" />
              <span>И все это в едином личном кабинете</span>
            </div>
          </div>
          <a
            href="https://panel.dostavkinbot.online/register"
            class="btn btn-main-gradient"
            >Начать сейчас</a
          >
        </div>
        <div class="description__img">
          <img
            src="@/assets/personal-area/personal-area-screen-bg.png"
            alt="description-image"
          />
        </div>
      </div>
      <img
        class="effect-bg"
        src="@/assets/personal-area/effect-bg.png"
        alt="effect-bg"
      />
      <img
        class="effect-blur"
        src="@/assets/personal-area/effect-blur.svg"
        alt="effect-blur"
      />
    </div>
  </div>
</template>

<script>
import Navbar from "../Navbar/Navbar";

export default {
  components: { Navbar },
  data: () => ({
    features: [
      "Подключайте готовые каналы продаж",
      "Обрабатывайте заказы",
      "Анализируйте клиентов и их заказы",
      "Повышайте число заказов с помощью инструментов маркетинга",
    ],
    width: 0,
    isMobileVersion: false,
  }),
  created() {
    window.addEventListener("resize", this.updateWidth);
    this.updateWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.updateWidth);
  },
  methods: {
    updateWidth() {
      this.width = window.innerWidth;
      this.width > 1000
        ? (this.isMobileVersion = false)
        : (this.isMobileVersion = true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.container-fluid {
  padding: 20px 30px;
  height: 100vh;
}

.personal-area-screen-content {
  padding: 30px 110px;
  height: 100%;
  border-radius: 20px;
  position: relative;
  background: $main-screen-bg-color;
  overflow: hidden;
}

.description {
  z-index: 10;
  display: flex;
  align-items: center;
  height: calc(100% - 30px);
}

.description__text {
  width: 55%;
}

.description__img {
  width: 45%;
  display: flex;
  align-items: center;
  z-index: 100;

  img {
    width: 120%;
  }
}

.effect-bg {
  width: 50%;
  position: absolute;
  bottom: 0px;
  right: -50px;
  z-index: 1;
  mix-blend-mode: screen;
}

.effect-blur {
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
}

h1 {
  font-size: 46px;
  line-height: 130%;
}

.description-features {
  .description-features__item {
    font-size: 18px;
    line-height: 145%;
    align-items: center;

    svg {
      width: 14px;
      height: 10px;
      margin-right: 5px;

      use {
        color: $main-color;
      }
    }

    &:first-child {
      margin-top: 25px;
    }

    margin-top: 15px;
  }

  .description-features__total {
    margin-top: 25px;

    img {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }

    span {
      font-size: 18px;
      font-weight: 600;
    }
  }
}

.btn-main-gradient {
  margin-top: 40px;
  display: inline-block;
}

@media (max-width: 1000px) {
  .container-fluid {
    height: unset;
    padding: 15px 15px 20px 15px;
    overflow: unset !important;
  }
  .personal-area-screen-content {
    margin-top: 15px;
    padding: 20px 15px;
    position: relative;
    overflow: unset !important;
  }
  .description {
    display: flex;
    flex-direction: column;

    .description__text {
      width: 100%;
      margin-top: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        text-align: center;
        font-size: 30px;
      }

      .description-features__item {
        &:first-child {
          margin-top: 20px;
        }

        margin-top: 10px;
        width: unset;
        text-align: center;
        font-size: 15px;
      }

      .description-features__total {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        span {
          font-size: 15px;
        }
      }

      .btn-main-gradient {
        margin-top: 20px;
      }
    }

    .description__img {
      margin-top: 35px;
      width: 100%;
      height: 260px;
      display: flex;
      justify-content: center;
      position: relative;

      img {
        max-width: 420px;
        max-height: 370px;
        position: absolute;
        top: 0;
        width: 110%;
      }
    }
  }
  .effect-bg {
    width: 70%;
    height: unset;
    bottom: 0;
    right: 0;
  }
  .effect-blur {
    width: 320px;
    height: 250px;
    bottom: 0;
    right: 0;
  }
}
</style>
