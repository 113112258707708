<template>
  <div class="container-fluid">
    <navbar />
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide
        :class="item.classSocial ? item.classSocial : ''"
        v-for="item in chatBotSliderItems"
        :key="item.id"
      >
        <div class="description">
          <div class="description__text">
            <h1>{{ item.header }}</h1>
            <h2 v-if="item.subheader">{{ item.subheader }}</h2>
            <div class="description-features">
              <div
                class="description-features__item"
                v-for="feature in item.features"
                :key="feature"
              >
                <svg class="arrow-icon">
                  <use href="@/assets/arrow.svg#arrow-icon"></use>
                </svg>
                {{ feature }}
              </div>
            </div>
            <div class="chat-bot-buttons">
              <a
                href="https://panel.dostavkinbot.online/register"
                class="btn btn-main-gradient"
                >Подключить</a
              >
              <a
                v-if="item.id === 6"
                target="_blank"
                :href="item.link"
                class="btn btn-white"
                >Смотреть сайт</a
              >
              <a v-else target="_blank" :href="item.link" class="btn btn-white"
                >Смотреть демо-бот</a
              >
            </div>
          </div>
          <div class="description__img">
            <a
              v-if="item.video"
              @click="openVideoModal(item)"
              class="video-button"
            >
              <span class="video-button-container">
                <img
                  class="video-play-icon"
                  src="@/assets/chat-bot-screen/video-buttons/video-btn.svg"
                  alt="video-play-icon"
                />
              </span>
              <img class="video-img" :src="item.video.img" alt="video-icon" />
            </a>
            <img
              class="description-image"
              :src="item.img"
              alt="description-image"
            />
          </div>
          <div class="bg-container">
            <img
              v-if="item.background.desktop"
              class="background-image"
              :src="item.background.desktop"
              alt="background-image"
            />
            <!--            <img v-else-if="isMobileVersion && item.background.mobile" class="background-image"-->
            <!--                 :src="item.background.mobile"-->
            <!--                 alt="background-image">-->
          </div>
        </div>
      </swiper-slide>
      <div
        class="swiper-pagination swiper-pagination-bullets"
        slot="pagination"
      ></div>
      <div class="swiper-button swiper-button-prev" slot="button-prev">
        <svg class="arrow-icon">
          <use href="@/assets/arrow.svg#arrow-icon"></use>
        </svg>
      </div>
      <div class="swiper-button swiper-button-next" slot="button-next">
        <svg class="arrow-icon">
          <use href="@/assets/arrow.svg#arrow-icon"></use>
        </svg>
      </div>
    </swiper>
    <transition name="fade" appear>
      <video-modal
        :currentVideoData="currentVideoData"
        @closeVideoModal="closeVideoModal"
        v-if="isVideoModalOpen"
      />
    </transition>
  </div>
</template>

<script>
import Navbar from "../Navbar/Navbar";
import VideoModal from "./VideoModal";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  props: ["startSlide"],
  components: {
    Navbar,
    Swiper,
    SwiperSlide,
    VideoModal,
  },
  data: () => ({
    swiperOption: {
      slidesPerView: 1,
      spaceBetween: 15,
      loop: true,
      centeredSlides: true,
      initialSlide: 0,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet(index, className) {
          return `<span class="${className} swiper-pagination-bullet-custom"></span>`;
        },
      },
    },

    chatBotSliderItems: [
      {
        id: 1,
        header: "Чат-бот в Instagram",
        subheader: "Ваш чат-бот в самой популярной соцсети!",
        features: [
          "Не теряйте заказы в переписках в Директе",
          "Превращайте ваших подписчиков в лояльных клиентов",
          "Просмотр меню и оформление заказа не выходя из Instagram DM",
        ],
        link: "https://ig.me/m/dostavkinbot_resto",
        img: require("@/assets/main-screen/slider/inst-slide.png"),
        background: {
          desktop: require("@/assets/chat-bot-screen/inst-bg.png"),
          mobile: require("@/assets/chat-bot-screen/inst-bg-mobile.png"),
        },
        classSocial: "swiper-slide-inst",
        video: {
          link: "https://www.youtube.com/embed/0aLTjPfSBq8",
          img: require("@/assets/chat-bot-screen/video-buttons/inst-video-btn.svg"),
        },
      },
      {
        id: 2,
        header: "Чат-бот в Telegram",
        features: [
          "Три вида бесплатных рассылок по собранным сегментам покупателей",
          "Интерактивный способ приема заказа с онлайн оплатой",
          "Отслеживание переходов по встроенным UTM-меткам",
        ],
        link: "https://t.me/dostavkinbot",
        img: require("@/assets/main-screen/slider/tg-slide.png"),
        background: {
          desktop: require("@/assets/chat-bot-screen/tg-bg.png"),
          mobile: require("@/assets/chat-bot-screen/tg-bg-mobile.png"),
        },
        video: {
          link: "https://www.youtube.com/embed/0gnN8P6bxCQ",
          img: require("@/assets/chat-bot-screen/video-buttons/tg-video-btn.svg"),
        },
        classSocial: "swiper-slide-tg",
      },
      {
        id: 3,
        header: "Чат-бот в Vkontakte",
        subheader: "Ваш чат-бот в самой популярной соцсети!",
        features: [
          "Оформление заказа не выходя из переписки ВК",
          "Уведомления о статусах заказа и запрос обратной связи от гостя",
          "Синхронизация с учетными и платежными системами",
        ],
        link: "https://vk.com/im?sel=-156376605",
        img: require("@/assets/main-screen/slider/vk-slide.png"),
        background: {
          desktop: require("@/assets/chat-bot-screen/vk-bg.png"),
          mobile: require("@/assets/chat-bot-screen/vk-bg-mobile.png"),
        },
        video: {
          link: "https://www.youtube.com/embed/1_TSbKX9QUk",
          img: require("@/assets/chat-bot-screen/video-buttons/vk-video-btn.svg"),
        },
        classSocial: "swiper-slide-vk",
      },
      {
        id: 4,
        header: "Чат-бот в Facebook",
        features: [
          "Не теряйте заказы в переписке в Facebook Messenger",
          "Сегментируйте покупателей по активности и заказам",
          "Уведомления о статусах заказа и запрос обратной связи от гостя",
        ],
        link: "https://www.facebook.com/messages/t/110711944790440",
        img: require("@/assets/main-screen/slider/fb-slide.png"),
        background: {
          desktop: require("@/assets/chat-bot-screen/fb-bg.png"),
          mobile: require("@/assets/chat-bot-screen/fb-bg-mobile.png"),
        },
        classSocial: "swiper-slide-fb",
        // video: {
        //   link: '',
        //   img: require('@/assets/chat-bot-screen/video-buttons/fb-video-btn.svg')
        // }
      },
      {
        id: 5,
        header: "Чат-бот в WhatsApp",
        subheader: "Бот в самом популярном мессенджере!",
        features: [
          "Оцифровка и сбор базы гостей",
          "Уведомления о статусах заказа и запрос обратной связи",
          "Удобное меню для приема заказа",
        ],
        link: "https://wa.me/79674550960/?text=Заказать",
        img: require("@/assets/main-screen/slider/wa-slide.png"),
        background: {
          desktop: require("@/assets/chat-bot-screen/wa-bg.png"),
        },
        video: {
          link: "https://www.youtube.com/embed/m83Uati4COs",
          img: require("@/assets/chat-bot-screen/video-buttons/wa-video-btn.svg"),
        },
        classSocial: "swiper-slide-wa",
      },
      {
        id: 6,
        header: "Сайт и qr-меню",
        subheader: "Сайт с моментальным запуском",
        features: [
          "Полностью функциональный сайт для приема заказов",
          "Возможность активировать сайт на вашем домене",
          "Сайт сам пригласит гостя отслеживать статус заказа в привычном ему мессенджере",
        ],
        background: {
          mobile: require("@/assets/chat-bot-screen/qr-bg-mobile.png"),
        },
        // video: {
        //   link: "https://www.youtube.com/embed/IF1_YpUbrNc",
        //   img: require("@/assets/chat-bot-screen/video-buttons/wa-video-btn.svg"),
        // },
        link: "https://dostavkinbot.online/DostavkinBot/",
        img: require("@/assets/chat-bot-screen/qr-slide.png"),
        classSocial: "swiper-slide-qr",
      },
    ],
    width: 0,
    isMobileVersion: false,
    isVideoModalOpen: false,
    currentVideoData: null,
  }),
  created() {
    window.addEventListener("resize", this.updateWidth);
    this.updateWidth();
    this.setInitialSlide();
  },
  destroyed() {
    window.removeEventListener("resize", this.updateWidth);
  },
  methods: {
    openVideoModal(item) {
      this.isVideoModalOpen = true;
      this.currentVideoData = item;
    },
    closeVideoModal() {
      this.isVideoModalOpen = false;
    },
    setInitialSlide() {
      if (this.startSlide) {
        this.swiperOption.initialSlide = this.startSlide;
      }
    },
    updateWidth() {
      this.width = window.innerWidth;
      this.width > 1000
        ? (this.isMobileVersion = false)
        : (this.isMobileVersion = true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.container-fluid {
  padding: 50px 0;
  height: 100vh;
}

.navbar {
  padding-left: 140px;
  padding-right: 140px;
}

.chat-bot-screen-content {
  margin-top: 25px;
  padding: 40px 115px;
  height: 100%;
  border-radius: 20px;
}

.description {
  width: 100%;
  height: 90%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;
}

.bg-container {
  border-radius: 30px;
  width: 100%;
  height: 90%;
  position: absolute;
  z-index: -1000;
  top: 20px;
  display: flex;
  align-items: flex-end;
  opacity: 0.5;
  background: $main-screen-bg-color;

  img {
    width: 100%;
    z-index: -1000;
  }
}

.description__text {
  width: 55%;
  padding-left: 80px;
}

.description__img {
  width: 45%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding-right: 100px;
  position: relative;

  .description-image {
    width: 75%;
    margin-top: 100px;
    margin-left: 15px;
    min-width: 280px;
    max-width: 340px;
    max-height: 590px;
  }
}

.video-button {
  margin-left: 30px;
  margin-top: 130px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    transform: scale(1.2);

    img.video-img {
      transform: rotate(360deg);
    }
  }

  .video-button-container {
    width: 50px;
    height: 50px;
    background: $main-color;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 20px;
      height: 20px;
    }
  }

  img.video-img {
    position: absolute;
    width: 94px;
    height: 94px;
    animation: 7s linear 0s normal none infinite running rot;
    -webkit-animation: 7s linear 0s normal none infinite running rot;
  }
}

@keyframes video-img {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

h1 {
  font-size: 46px;
  line-height: 120%;
}

h2 {
  font-size: 24px;
  line-height: 120%;
  font-weight: 600;
  margin-top: 20px;
}

.description-features {
  .description-features__item {
    font-size: 18px;
    line-height: 145%;
    text-align: left;

    &:first-child {
      margin-top: 25px;
    }

    svg {
      width: 14px;
      height: 10px;
      margin-right: 5px;

      use {
        color: $main-color;
      }
    }

    margin-top: 15px;
  }
}

.chat-bot-buttons {
  margin-top: 35px;
  display: flex;
}

.btn-main-gradient {
  margin-right: 20px;
}

.btn-main-gradient,
.btn-white {
  display: inline-block;
}

.swiper {
  height: 100%;
  padding-left: 140px;
  padding-right: 140px;
  position: relative;

  .swiper-button {
    width: 26px;
    height: 22px;
    background: transparent;
    top: unset;
    bottom: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    use {
      color: $main-color;
    }

    &:hover {
      use {
        color: $green-color;
      }
    }

    &.swiper-button-prev {
      left: 140px;

      svg {
        transform: rotate(180deg);
      }
    }

    &.swiper-button-next {
      left: 475px;
    }

    svg {
      width: 14px;
      height: 12px;
    }
  }

  .swiper-pagination {
    display: flex;
    justify-content: flex-start;
    bottom: 40px !important;
    padding: 0 180px;

    /deep/ .swiper-pagination-bullet-custom {
      width: 30px;
      height: 30px;
      opacity: 0.4;
      margin-right: 15px;

      &:nth-child(1) {
        background: url("~@/assets/chat-bot-screen/inst-slide-icon.svg");
      }

      &:nth-child(2) {
        background: url("~@/assets/chat-bot-screen/tg-slide-icon.svg");
      }

      &:nth-child(3) {
        background: url("~@/assets/chat-bot-screen/vk-slide-icon.svg");
      }

      &:nth-child(4) {
        background: url("~@/assets/chat-bot-screen/fb-slide-icon.svg");
      }

      &:nth-child(5) {
        background: url("~@/assets/chat-bot-screen/wa-slide-icon.svg");
      }

      &:nth-child(6) {
        background: url("~@/assets/chat-bot-screen/qr-slide-icon.svg");
        margin-right: 0;
      }

      &:hover {
        opacity: 1;
      }

      &.swiper-pagination-bullet-active {
        opacity: 1;
      }
    }
  }

  .swiper-slide {
    height: 100%;

    &.swiper-slide-inst {
      .bg-container {
        background: $inst-slider-bg;
      }
    }

    &.swiper-slide-fb {
      .bg-container {
        background: #9bbaeb;
      }
    }

    &.swiper-slide-qr {
      .bg-container {
        background: $main-screen-bg-color;
      }

      .description-image {
        max-width: unset;
        max-height: unset;
      }

      .description__text {
        width: 50%;
      }

      .description__img {
        width: 50%;
        margin-top: 0;
        padding-right: 0;

        img {
          width: 100%;
          max-width: 600px;
        }
      }
    }
  }
}

@media (max-width: 1000px) {

  .container-fluid {
    height: unset;
    padding: 15px 15px 35px 15px;
  }
  .navbar {
    padding: 0;
  }
  .main-screen-content {
    padding: 20px 25px;
  }
  .description {
    padding: 15px;
    display: flex;
    flex-direction: column;

    .description__text {
      width: 100% !important;
      margin-top: 45px;
      padding-left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        text-align: center;
        font-size: 30px;
      }

      h2 {
        margin-top: 15px;
        text-align: center;
        font-size: 18px;
      }

      .description-features__item {
        width: unset;
        text-align: center;
        font-size: 15px;
        margin-top: 10px;

        &:first-child {
          margin-top: 10px;
        }
      }
    }

    .bg-container {
      height: calc(100% - 35px);
    }

    .description__img {
      width: 100% !important;
      padding-top: 30px;
      height: 300px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      padding-right: 0;
      position: relative;

      .description-image {
        width: unset;
        height: 340px;
        max-height: 340px;
        min-width: unset;
        margin-left: 0;
        margin-top: 0;
      }

      .video-button {
        width: 70px;
        height: 70px;
        position: absolute;
        right: 10px;
        margin-top: 20px;

        .video-button-container {
          width: 36px;
          height: 36px;

          img {
            width: 10px;
            height: 10px;
          }
        }

        .video-img {
          width: 70px;
          height: 70px;
        }
      }
    }
  }
  .swiper-slide-inst {
    .bg-container {
      background: #eef1ff !important;
    }
  }
  .swiper-slide-tg {
    .bg-container {
      background: #eef1ff !important;
    }
  }
  .swiper-slide-vk {
    .bg-container {
      background: #f3f5f8 !important;
    }
  }
  .swiper-slide-wa {
    .bg-container {
      background: #eef5ff !important;
    }
  }
  .swiper-slide-fb {
    .bg-container {
      background: #edf4ff !important;
    }
  }
  .swiper-slide-qr {
    .bg-container {
      background: #eef5ff !important;
    }

    .description-image {
      height: unset !important;
      max-height: unset !important;
    }

    .description__img {
      img {
        width: 100% !important;
      }
    }
  }

  .swiper {
    padding: 0;

    .swiper-button {
      bottom: 4px;

      &.swiper-button-prev {
        left: 0;
      }

      &.swiper-button-next {
        left: unset;
        right: 0;
      }
    }

    .swiper-pagination {
      margin-top: 20px;
      position: unset;
      justify-content: center;
      padding: 0;
    }
  }

  .chat-bot-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .btn {
    font-size: 15px;
    padding: 10px 10px;
    display: flex;
    justify-content: center;
  }
  .btn-main-gradient {
    width: 42%;
  }
  .btn-white {
    width: 58%;
  }
}
</style>
