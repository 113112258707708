<template>
  <div class="container-fluid">
    <h1>Возможности и преимущества чат-ботов:</h1>
    <div class="features-list">
      <features-item v-for="item in items" :key="item.id" :item="item" />
    </div>
    <a
      href="https://panel.dostavkinbot.online/register"
      class="btn btn-main-gradient"
      >Начать сейчас</a
    >
  </div>
</template>

<script>
import FeaturesItem from "../../ChatBot/Features/FeaturesItem";

export default {
  components: { FeaturesItem },
  data: () => ({
    items: [
      {
        id: 1,
        header: {
          name: "Автоматизация \n" + "приема заказов",
          image: require("@/assets/chat-bot-screen/profits/profit-item1.png"),
        },
        profits: [
          "Обрабатывайте больше заказов, не увеличивая количество операторов",
          "Не теряйте заказы из-за человеческого фактора",
        ],
        notice: "Вы не теряете заказы и вовремя обрабатываете их",
      },
      {
        id: 2,
        header: {
          name: "Гибкая настройка \n" + "стоимости доставки",
          image: require("@/assets/chat-bot-screen/profits/profit-item2.png"),
        },
        profits: [
          "Добавит стоимость доставки в зависимости от зоны доставки и суммы заказа",
          "Задавайте зоны доставки с индивидуальными настройками",
        ],
        notice: "Настраивайте зоны доставки и не теряйте прибыль",
      },
      {
        id: 3,
        header: {
          name: "Интеграция \n" + "с платежными \n" + "системами и CRM",
          image: require("@/assets/chat-bot-screen/profits/profit-item3.png"),
        },
        profits: [
          "Возможность подключить онлайн-оплату",
          "Выгрузка меню из API учетной системы ",
          "Все заказы синхронно приходят в вашу учетную системы (iiko, Poster, FrontPad и т.д.)",
        ],
        notice: "Готовое решение с возможностью настройки под любое заведение",
      },
      {
        id: 4,
        header: {
          name: "Уведомления \n" + "о смене статуса \n" + "заказа",
          image: require("@/assets/chat-bot-screen/profits/profit-item4.png"),
        },
        profits: [
          "Автоматический уведомит гостя о смене статуса заказа: “Принят”, “Готовится” и тп.",
          "Гости не будут звонить с вопросами: “Где мой заказ?”",
        ],
        notice: "Экономите на смс и повышаете лояльность ваших клиентов",
      },
      {
        id: 5,
        header: {
          name: "Сбор отзывов и \n" + "быстрая обратная \n" + "связь",
          image: require("@/assets/chat-bot-screen/profits/profit-item5.png"),
        },
        profits: [
          "Автоматический собирает отзывы",
          "Лучшие отзывы дублирует на публичные платформы (2GIS, Yandex Карты, и т. д.)",
        ],
        notice: "Растет рейтинг - заведение продвигается в поисковой выдаче",
      },
      {
        id: 6,
        header: {
          name: "Уведомления \n" + "об акциях",
          image: require("@/assets/chat-bot-screen/profits/profit-item6.png"),
        },
        profits: [
          "Бесплатная рассылка всем гостям",
          "Гибкая настройка рассылок по отдельным группам гостей",
        ],
        notice: "Открываемость рассылок в мессенджерах до 90%",
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.container-fluid {
  padding: 120px 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  font-size: 36px;
  text-align: center;
  font-weight: 600;
}

.features-list {
  display: flex;
  flex-wrap: wrap;
}

.btn-main-gradient {
  margin-top: 70px;
  font-size: 18px;
  display: inline-block;
}

@media (max-width: 1000px) {
  .container-fluid {
    padding: 70px 15px 90px 15px;
  }
  h1 {
    font-size: 24px;
  }
  .features-list {
    margin-top: 0;
    flex-direction: column;
  }
  .btn-main-gradient {
    display: none;
  }
}
</style>
