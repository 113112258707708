<template>
  <div class="error-notice-container">
    <img
      @mouseenter="isDropdownOpen = true"
      @mouseleave="isDropdownOpen = false"
      src="@/assets/base/question.svg"
      alt="notice-icon"
    />
    <transition name="fade">
      <div v-if="isDropdownOpen" class="error-notice-helper">
        {{ notice }}
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["notice"],
  data: () => ({
    isDropdownOpen: false,
  }),
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.error-notice-container {
  position: absolute;
  right: 30px;
}
.error-notice-helper {
  width: 100px;
  padding: 10px;
  border-radius: 5px;
  background: $white-color;
  position: absolute;
  right: 0;
  top: 45px;
  font-size: 10px;
  font-weight: 600;
  text-align: left;
}
</style>
