<template>
  <div @mouseover="setRadioMonthAttr(tariffItem.id)" @mouseleave="clearRadios" v-click-outside="clearRadios"
       @click="setRadioMonthAttr(tariffItem.id)" class="tariff-item-container">
    <div class="tariff-item">
      <div class="tariff-item__top-container">
        <span class="tariff-item__header">{{ tariffItem.type }}</span>
        <div class="tariff-item__info">
          <div class="tariff-item__text">
            <span>{{ tariffItem.title }}</span>
            <span>{{ getTitle }}</span>
          </div>
          <div class="tariff-item__socials">
            <img
                v-for="social in tariffItem.socials"
                :key="social.name"
                :src="social.icon"
                alt="social-icon"
            />
          </div>
          <div class="tariff-item__features">
            <div class="features__base" v-for="feature in tariffItem.features" :key="feature">
              <img src="@/assets/okaygreen.svg" alt="okay-icon"/>
              <span>{{ feature }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="tariff-item__footer">
        <div class="footer-table">
          <div class="form-check">
            <label class="form-check-label">
              <input
                  :id="radioMonthId"
                  @change="setRadioMonthAttr(tariffItem.id)"
                  :checked="radioMonthId"
                  class="form-check-input"
                  name="radioName"
                  type="radio"/>
              <span>1 месяц</span>
            </label>
            <span>{{ tariffItem.price.month }} ₽/мес</span>
          </div>
          <div class="form-check">
            <label class="form-check-label">
              <input
                  @change="setRadioYearAttr(tariffItem.id)"
                  :checked="radioYearId"
                  :id="radioYearId"
                  class="form-check-input"
                  name="radioName"
                  type="radio"
              />
              <span>12 месяцев</span>
            </label>
            <span>
              {{ tariffItem.price.year }} ₽/мес
              <tariff-discount :discount="tariffItem.price.discount"/>
            </span>
          </div>
        </div>
        <button @click="goToRegister"
                :class="
                radioMonthId || radioYearId
                ? 'btn-green-gradient'
                : 'btn-grey'
                "
                :disabled="radioMonthId || radioYearId ? false : true"
                class="btn">
          Подключить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import TariffDiscount from "./TariffDiscount";

export default {
  components: {TariffDiscount},
  props: ['tariffItem'],
  data: () => ({
    radioMonthId: null,
    radioYearId: null,
  }),
  methods: {
    setRadioMonthAttr() {
      this.radioMonthId = this.getPrefix + "RadioMonthId";
    },
    setRadioYearAttr() {
      this.radioYearId = this.getPrefix + "RadioYearId";
    },
    clearRadios() {
      this.radioMonthId = null;
      this.radioYearId = null;
    },
    goToRegister() {
      window.location.href = "https://panel.dostavkinbot.online/register"
    }
  },
  computed: {
    getPrefix(id) {
      switch (id) {
        case "1":
          return "one";
        case "2":
          return "two";
        case "3":
          return "three";
        default:
          return "all";
      }
    },
    getTitle() {
      switch (this.tariffItem.id) {
        case 1:
          return "продаж на выбор: ";
        case 2:
          return "продаж на выбор: ";
        case 3:
          return "продаж на выбор: ";
        default:
          return "продаж: ";
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.tariff-item-container {
  width: 25%;
  padding-left: 20px;
  padding-right: 20px;
}

.tariff-item {
  width: 100%;
  height: 100%;
  min-height: 500px;
  padding: 20px;
  transition: 0.3s;
  position: relative;
  z-index: 1000;
  border-radius: 10px;
  border: 1px solid $tariffs-item-non-active-color;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  }
}

.tariff-item__info {
  margin-top: 10px;
}

.tariff-item__header {
  font-family: 'Montserrat';
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.01em;
  color: $main-color;
}

.tariff-item__text {
  display: flex;
  flex-direction: column;

  span {
    line-height: 120%;

    &:first-child {
      font-size: 24px;
      font-weight: 600;
    }

    &:last-child {
      font-size: 16px;
    }
  }
}

.tariff-item__socials {
  margin-top: 15px;
  display: flex;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}


.tariff-item__features {
  margin-top: 20px;

  span {
    font-size: 11px;
    font-weight: 400;
  }

  img {
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }
}

.features__base {
  img {
    width: 8px;
    height: 6px;
  }

  &:first-child {
    span {
      font-weight: 600;
      font-size: 14px;
    }
  }
}

.footer-table {
  margin-top: 30px;

  .form-check {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $tariffs-item-non-active-color;

    &:last-child {
      span {
        display: flex;
        align-items: center;
        font-size: 12px;
      }
    }
  }

  .form-check-label {
    span {
      padding-left: 5px;
      font-weight: 400;
    }
  }

  span {
    font-weight: 700;
  }
}

.btn {
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 1000px) {
  .tariff-item-container {
    width: 100%;
    margin-bottom: 30px;

    .tariff-item {
      min-height: unset;
      position: relative;
      padding: 25px;
    }

    .tariff-item__top-container {
      .tariff-item__header {
        font-size: 14px;
        position: absolute;
        right: 20px;
        top: 20px;
      }

      .tariff-item__info {
        margin-top: 0;
      }

      .tariff-item__text {
        span {
          &:last-child {
            font-size: 18px;
          }
        }
      }

      .tariff-item__socials {
        img {
          width: 36px;
          height: 36px;
        }
      }

      .tariff-item__features {
        .features__base {
          img {
            width: 10px;
            height: 7px;
          }

          &:first-child {
            span {
              font-size: 16px;
            }
          }
        }
      }
    }

    .tariff-item__footer {
      .footer-table {
        .form-check {
          input {
            width: 20px;
            height: 20px;
          }

          label {
            font-size: 14px;
          }

          &:last-child {
            span {
              font-size: 14px;
            }
          }

          &:last-child {
            border-bottom: 1px solid $tariffs-item-non-active-color;
          }
        }
      }

      .btn {
        margin-top: 25px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>