<template>
  <div class="partners-item">
    <img :src="item.img" alt="partner-icon">
  </div>
</template>

<script>
export default {
  props: ['item']
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.partners-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 50px;

  &:nth-child(2), &:last-child {
    margin-top: 10px;
  }
}

@media (min-width: 1000px) and (max-width: 1350px) {
  .partners-item {
    padding: 0 20px;
  }
}

@media (max-width: 1000px) {
  .partners-item {
    width: 100%;
    margin-bottom: 20px;

    &:nth-child(2), &:nth-child(3) {
      width: 50%;
      margin-bottom: 0;
    }

    &:last-child {
      margin-top: 20px;
      margin-bottom: 0;
    }
  }
}
</style>