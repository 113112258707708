<template>
  <div class="container-fluid">
    <div class="banner-text">
      <h1>Всё для обработки заказов, оценки эффективности рекламы и маркетинга в одном сервисе</h1>
      <h2>Клиенты делают заказы через готовые каналы: Instagram, Facebook, Telegram, VK, WhatsApp, сайт. Вам остается их
        обработать и оценить качество обслуживания с помощью отзывов. </h2>
      <h2>Мы объединили разные инструменты аналитики и маркетинга в один сервис, чтобы вам было проще обрабатывать все
        заказы и оценивать эффективность рекламы, которая их привела. </h2>
    </div>
    <img class="banner-img" src="@/assets/personal-area/banner/banner-bg.png" alt="banner-image">
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.container-fluid {
  width: 100%;
  background: $purple-color;
  padding: 80px 140px;
  display: flex;
  position: relative;
}

.banner-text {
  width: 50%;

  h1 {
    font-size: 30px;
    font-weight: 600;
    color: $white-color;
    line-height: 135%;
  }

  h2 {
    margin-top: 35px;
    font-size: 18px;
    color: $white-color;
    font-weight: 400;
    line-height: 145%;

    &:first-child {
      margin-top: 30px;
    }
  }
}

.banner-img {
  height: 100%;
  top: 0;
  right: 140px;
  position: absolute;
}


@media (max-width: 1000px) {
  .container-fluid {
    padding: 50px 20px 0px 20px;
    flex-direction: column;
    align-items: center;
  }
  .banner-text {
    width: 100%;

    h1 {
      font-size: 24px;
      text-align: center;
    }

    h2 {
      font-size: 16px;
      text-align: center;
      margin-top: 30px;
    }
  }
  .banner-img {
    width: 110%;
    position: unset;
    height: unset;
    max-height: 350px;
  }
}
</style>