<template>
  <div class="container-fluid">
    <navbar/>
    <h1 v-if="isMobileVersion">Тарифы</h1>
    <banner/>
    <h1 v-if="!isMobileVersion">Тарифы</h1>
    <h2>Выберите тариф в зависимости от необходимого количества <b> каналов продаж: </b></h2>
    <tariff-list/>
    <enterprise/>
  </div>
</template>

<script>
import Navbar from "../Navbar/Navbar";
import TariffList from "./TariffsList/TariffList";
import Banner from "../Tariffs/Banner/Banner";
import Enterprise from "./Enterprise/Enterprise";

export default {
  components: {Enterprise, Banner, TariffList, Navbar},
  data: () => ({
    width: 0,
    isMobileVersion: false
  }),
  created() {
    window.addEventListener("resize", this.updateWidth);
    this.updateWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.updateWidth);
  },
  methods: {
    updateWidth() {
      this.width = window.innerWidth;
      this.width > 1000 ? (this.isMobileVersion = false) : (this.isMobileVersion = true);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.container-fluid {
  padding: 50px 0 120px 0;
}

.navbar, .tariffs-list {
  padding: 0 140px;
}

h1, h2 {
  text-align: center;
}

h1 {
  margin-top: 70px;
  font-size: 46px;
  font-weight: 600;
}

h2 {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 400;
}

@media (max-width: 1000px) {
  .container-fluid {
    height: unset;
    padding: 15px 0 100px 0;
  }
  .navbar, .tariffs-list {
    padding: 0 15px;
  }
  h1 {
    margin-top: 40px;
    font-size: 46px;
  }
  h2 {
    margin-top: 40px;
    font-size: 21px;
    padding: 0 20px;
    text-align: left;
  }
}
</style>