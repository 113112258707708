<template>
  <div class="feature-item">
    <div class="img-container">
      <img :src="item.img" alt="feature-image">
    </div>
    <div class="item-text">
      <span>{{ item.name }}</span>
      <span>{{ item.description }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item']
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.feature-item {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;

  img {
    position: absolute;
  }

  &:first-child {
    img {
      width: 190px;
      height: 205px;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &:nth-child(2) {
    img {
      width: 260px;
      height: 180px;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &:nth-child(3) {
    img {
      width: 260px;
      height: 180px;
      bottom: 0;
      right: 0;
    }
  }

  &:last-child {
    img {
      width: 180px;
      height: 180px;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.img-container {
  width: 100%;
  height: 150px;
  background: linear-gradient(0deg, #70C0FC, #70C0FC), radial-gradient(68.19% 62.5% at 50% 45.72%, rgba(36, 205, 246, 0) 32.29%, rgba(36, 205, 246, 0.75) 80.21%, #24CDF6 100%), #8FE8FF;
  background-blend-mode: color, normal, normal;
  border-radius: 10px;
  position: relative;
}

.item-text {
  margin-top: 30px;
  display: flex;
  flex-direction: column;

  span {
    width: 100%;
    text-align: center;

    &:first-child {
      font-size: 21px;
      font-weight: 600;
    }

    &:last-child {
      margin-top: 5px;
      font-size: 14px;
    }
  }
}

@media (max-width: 768px) {
  .feature-item {
    margin-top: 30px;
    width: 50%;
    padding-left: 10px;
    padding-right: 10px;

    .img-container {
      height: 90px;
    }

    &:first-child {
      img {
        width: 120px;
        height: 125px;
      }
    }

    &:nth-child(2) {
      img {
        width: 150px;
        height: 110px;
        bottom: 0px;
      }
    }

    &:nth-child(3) {
      img {
        width: 160px;
        height: 100px;
      }
    }

    &:last-child {
      img {
        width: 110px;
        height: 110px;
      }
    }

    .item-text {
      margin-top: 10px;

      span {
        &:first-child {
          margin-top: 10px;
          font-size: 15px;
        }

        &:last-child {
          font-size: 11px;
          line-height: 145%;
        }
      }
    }
  }
}
</style>