import Vue from 'vue'
import VueRouter from 'vue-router'
import main from '../views/main'
import chatBot from '../views/chatBot'
import tariffs from '../views/tariffs'
import personalArea from "../views/personalArea";
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'main-view',
        component: main
    },
    {
        path: '/chat-bot',
        name: 'chat-bot-view',
        component: chatBot,
        props: true
    },
    {
        path: '/tariffs',
        name: 'tariffs-view',
        component: tariffs
    },
    {
        path: '/personal-area',
        name: 'personal-area-view',
        component: personalArea
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
})

export default router
