<template>
  <div class="container-fluid">
    <personal-area-screen/>
    <features/>
    <banner/>
    <opportunities/>
    <Footer/>
  </div>
</template>

<script>
import PersonalAreaScreen from "../components/PersonalArea/PersonalAreaScreen";
import Footer from "../components/Footer/Footer";
import Features from "../components/PersonalArea/Features/Features";
import Banner from "../components/PersonalArea/Banner/Banner"
import Opportunities from "../components/PersonalArea/Opportunities/Opportunities";

export default {
  name: 'personal-area-view',
  components: {Opportunities, Features, Footer, PersonalAreaScreen, Banner},
}
</script>

<style lang="scss" scoped>
.container-fluid {
  overflow: hidden;
}
</style>