<template>
  <div class="navbar">
    <div class="links">
      <router-link to="/">
        <img class="logo" src="@/assets/logo.svg" alt="logo-icon" />
      </router-link>
      <img
        src="@/assets/burger-menu.png"
        @click="isDropdownOpen = true"
        v-if="isMobileVersion"
        class="burger"
      />
      <ul v-else class="menu">
        <li v-for="navItem in navbar" :key="navItem.id">
          <router-link
            active-class="router-link-active"
            :to="navItem.path"
            exact
          >
            {{ navItem.name }}
          </router-link>
        </li>
        <!--        next version-->
        <!--        <navbar-dropdown/>-->
        <!--        <li>-->
        <!--          <router-link to="">Тарифы</router-link>-->
        <!--        </li>-->
        <!--        <li>-->
        <!--          <router-link to="">Партнерам</router-link>-->
        <!--        </li>-->
      </ul>
    </div>
    <div v-if="!isMobileVersion" class="sign-actions">
      <a target="_blank" href="https://panel.dostavkinbot.online/">Войти</a>
      <a
        target="_blank"
        class="sign-up-action"
        href="https://panel.dostavkinbot.online/register"
      >
        Начать сейчас
        <svg class="arrow-icon">
          <use href="@/assets/arrow.svg#arrow-icon"></use>
        </svg>
      </a>
    </div>
    <transition name="fade">
      <div
        v-if="isMobileVersion === true && isDropdownOpen"
        class="mobile-dropdown-links"
      >
        <div class="mobile-dropdown__header">
          <router-link to="/">
            <img class="logo" src="@/assets/logo.svg" alt="logo-icon" />
          </router-link>
          <img
            src="@/assets/burger-close.png"
            class="exit"
            @click="isDropdownOpen = false"
          />
        </div>
        <ul class="menu">
          <li v-for="navItem in navbar" :key="navItem.id">
            <router-link
              active-class="router-link-active"
              :to="navItem.path"
              exact
            >
              {{ navItem.name }}
            </router-link>
          </li>
          <!--          <navbar-dropdown/>-->
        </ul>
        <div class="sign-actions">
          <a href="https://panel.dostavkinbot.online/">Войти</a>
          <a
            class="sign-up-action"
            href="https://panel.dostavkinbot.online/register"
          >
            Начать сейчас
            <svg class="arrow-icon">
              <use href="@/assets/arrow.svg#arrow-icon"></use>
            </svg>
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// import NavbarDropdown from "./NavbarDropdown";

export default {
  // components: {NavbarDropdown},
  data: () => ({
    isMobileVersion: false,
    isDropdownOpen: false,
    width: 0,
    activeLink: "",
    navbar: [
      {
        id: 1,
        name: "Главная",
        path: "/",
      },
      {
        id: 2,
        name: "Чат-боты и сайт",
        path: "/chat-bot",
      },
      {
        id: 3,
        name: "Личный кабинет",
        path: "/personal-area",
        adaptive: true,
      },
      {
        id: 4,
        name: "Тарифы",
        path: "/tariffs",
        adaptive: true,
      },
    ],
  }),
  created() {
    window.addEventListener("resize", this.updateWidth);
    this.updateWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.updateWidth);
  },
  methods: {
    updateWidth() {
      this.width = window.innerWidth;
      this.width > 1000
        ? (this.isMobileVersion = false)
        : (this.isMobileVersion = true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.logo {
  width: 190px;
  height: 40px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 10000;
}

.router-link-active {
  color: $main-color;
}

.links {
  display: flex;
  align-items: center;

  img {
    margin-right: 100px;
  }
}

ul {
  display: flex;

  li {
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.sign-actions {
  display: flex;
  align-items: center;

  a {
    margin-right: 40px;
    font-weight: 600;

    &.sign-up-action {
      border-bottom: 2px solid $main-color;

      &:hover {
        svg {
          use {
            color: $main-color;
          }
        }
      }

      svg {
        width: 13px;
        height: 10px;

        use {
          color: $black-color;
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

a {
  font-size: 15px;
  font-weight: 500;

  &:hover {
    color: $main-color !important;
  }
}

@media (min-width: 1000px) and (max-width: 1350px) {
  .links {
    .logo {
      width: 172px;
      height: 32px;
      margin-right: 40px;
    }
  }
  ul {
    li {
      a {
        font-size: 13px;
      }
    }
  }
  .sign-actions {
    a {
      font-size: 13px;
    }
  }
}

@media (max-width: 1000px) {
  .logo {
    width: 172px;
    height: 32px;
    margin-right: 0 !important;
  }
  .links {
    width: 100%;
    justify-content: space-between;
  }
  .burger {
    width: 20px;
    height: 16px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: fixed;
    right: 15px;
    top: 25px;
    z-index: 1000;
    margin-right: 0 !important;
  }
  .mobile-dropdown-links {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: -webkit-fill-available;
    background: $white-color;
    position: fixed;
    display: flex;
    flex-direction: column;
    padding: 15px;
    box-shadow: 0px 4px 10px rgba(33, 71, 129, 0.1);
    z-index: 1000;

    .mobile-dropdown__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .exit {
      width: 15px;
      height: 15px;
      margin-right: 0;
    }

    .sign-actions {
      width: 100%;
      position: absolute;
      bottom: 50px;
      left: 0;
      flex-direction: column;
      align-items: center;

      a {
        margin-right: 0;

        &:first-child {
          background: $main-color;
          padding: 10px 50px;
          color: $white-color;
          font-size: 21px;
          font-weight: 600;
          border-radius: 100px;
          margin-bottom: 20px;
        }

        &:last-child {
          font-size: 21px;
          border-bottom: 3px solid $main-color;
        }
      }
    }

    ul {
      margin-top: 80px;
      display: flex;
      flex-direction: column;

      .dropdown-container {
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
      }

      li {
        margin-bottom: 20px;
        margin-right: 0;
        text-align: center;

        a {
          font-size: 21px;
          font-weight: 600;
        }
      }
    }
  }
}
</style>
