<template>
  <div class="start-step-item">
    <div class="start-step-item__text">
      <div class="step">{{ item.id }}</div>
      <span class="item-header">{{ item.header }}</span>
      <span class="item-description">{{ item.description }}</span>
    </div>
    <div class="start-step-item__img-container">
      <div class="container__bg-color">
        <img :src="item.img" alt="step-image">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item']
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.start-step-item {
  margin-top: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .step {
    width: 50px;
    height: 50px;
    background: $green-color;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white-color;
    font-size: 24px;
    font-weight: 700;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .item-header {
    font-size: 24px;
    font-weight: 600;
    text-align: right;
    padding-left: 30%;
  }

  .item-description {
    font-size: 18px;
    margin-top: 20px;
    text-align: right;
    padding-left: 30%;
    line-height: 155%;
  }

  &:first-child {
    margin-top: 0;

    .start-step-item__img-container {
      img {
        width: 310px;
        height: 260px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

  }

  &:nth-child(2) {
    flex-direction: row-reverse;

    .item-header {
      font-size: 24px;
      font-weight: 600;
      text-align: left;
      padding-right: 30%;
      padding-left: 0;
    }

    .item-description {
      font-size: 18px;
      margin-top: 20px;
      text-align: left;
      padding-left: 0;
      padding-right: 30%;
    }

    .start-step-item__img-container {

      img {
        width: 390px;
        height: 300px;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }
    }
  }

  &:last-child {
    .start-step-item__img-container {
      height: 340px;

      img {
        width: 463px;
        height: 295px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.start-step-item__text {
  width: 45%;
  display: flex;
  flex-direction: column;
}

.start-step-item__img-container {
  width: 45%;
  height: 220px;


  .container__bg-color {
    width: 100%;
    height: 100%;
    background: #F0F4FF;
    border-radius: 30px;
    position: relative;
  }
}

@media (max-width: 1000px) {
  .start-step-item {
    flex-direction: column;

    .step {
      left: 20px;
    }

    .item-header, .item-description {
      padding-left: 0;
    }

    .item-description {
      margin-top: 5px;
    }

    &:first-child {

      .start-step-item__img-container {
        img {
          width: 235px;
          height: 194px;
        }
      }
    }

    &:nth-child(2) {
      flex-direction: column;

      .start-step-item__text {
        width: 100%;

        .item-header {
          font-size: 20px;
          text-align: left;
          padding-right: 0;
        }

        .item-description {
          font-size: 15px;
          text-align: left;
          padding-right: 0;
        }


      }

      .start-step-item__img-container {
        img {
          width: 250px;
          height: 185px;
        }
      }
    }

    &:last-child {
      .start-step-item__img-container {
        height: 200px;

        img {
          width: 275px;
          height: 175px;
        }
      }
    }
  }
  .start-step-item__text {
    width: 100%;

    .item-header {
      font-size: 20px;
      text-align: left;
    }

    .item-description {
      font-size: 15px;
      text-align: left;
    }


  }
  .start-step-item__img-container {
    margin-top: 15px;
    width: 100%;
    height: 160px;

    img {
      height: 120%;
    }
  }
}
</style>