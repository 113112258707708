<template>
  <div class="container-fluid">
    <main-screen/>
    <social-slider/>
    <features/>
    <tasks/>
    <start-work/>
    <clients/>
    <feedback-slider/>
    <Footer/>
  </div>
</template>

<script>
import MainScreen from "../components/MainScreen/MainScreen";
import SocialSlider from "../components/MainScreen/SocialSlider/SocialSlider";
import Features from "../components/MainScreen/Features/Features";
import Tasks from "../components/MainScreen/Tasks/Tasks"
import StartWork from "../components/MainScreen/StartWork/StartWork";
import Clients from "../components/MainScreen/Clients/Clients";
import FeedbackSlider from "../components/MainScreen/FeedbackSlider/FeedbackSlider";
import Footer from "../components/Footer/Footer";

export default {
  name: 'main-view',
  components: {FeedbackSlider, Clients, StartWork, Features, MainScreen, SocialSlider, Tasks, Footer},
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.container-fluid {
  overflow: hidden;
}

</style>
