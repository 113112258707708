<template>
  <div class="container-fluid">
    <tariffs-screen/>
    <Footer/>
  </div>
</template>

<script>
import TariffsScreen from "../components/Tariffs/TariffsScreen";
import Footer from "../components/Footer/Footer";

export default {
  name: 'tariffs-view',
  components: {TariffsScreen, Footer}
}
</script>

<style lang="scss" scoped>
.container-fluid {
  overflow: hidden;
}
</style>