<template>
  <div class="slider-container">
    <h1>Готовые решения для сбора заказов через соцсети и мессенджеры</h1>
    <h2>
      Посмотрите наши демо-боты, пролистайте меню и попробуйте сделать заказ
      глазами вашего гостя
    </h2>
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide
        :class="item.isBigImage ? 'big-image' : ''"
        v-for="item in items"
        :key="item.id"
      >
        <img
          :class="item.id === 0 ? 'slide-inst' : ''"
          :src="isMobileVersion && item.imgMob ? item.imgMob : item.img"
          alt="slide-image"
        />
        <span @click="logItem(item.id)">{{ item.description }}</span>
        <router-link
          class="btn btn-main-gradient"
          :to="{ name: 'chat-bot-view', params: { startSlide: item.id } }"
        >
          Подробнее
        </router-link>
      </swiper-slide>
      <div
        class="swiper-pagination swiper-pagination-bullets"
        slot="pagination"
      ></div>
      <div class="swiper-button swiper-button-prev" slot="button-prev">
        <svg class="arrow-icon">
          <use href="@/assets/arrow.svg#arrow-icon"></use>
        </svg>
      </div>
      <div class="swiper-button swiper-button-next" slot="button-next">
        <svg class="arrow-icon">
          <use href="@/assets/arrow.svg#arrow-icon"></use>
        </svg>
      </div>
    </swiper>

    <div class="bg-blur bg-blur-first"></div>
    <div class="bg-blur bg-blur-second"></div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data: () => ({
    items: [
      {
        id: 0,
        name: "Instagram",
        img: require("@/assets/main-screen/slider/inst-slide.png"),
        description: "Чат-бот в Instagram",
      },
      {
        id: 1,
        name: "Telegram",
        img: require("@/assets/main-screen/slider/tg-slide.png"),
        description: "Чат-бот в Telegram",
      },
      {
        id: 2,
        name: "VK",
        img: require("@/assets/main-screen/slider/vk-slide.png"),
        description: "Чат-бот в VK",
      },
      {
        id: 3,
        name: "Facebook",
        img: require("@/assets/main-screen/slider/fb-slide.png"),
        description: "Чат-бот в Facebook",
      },
      {
        id: 4,
        name: "WhatsApp",
        img: require("@/assets/main-screen/slider/wa-slide.png"),
        description: "Чат-бот в WhatsApp",
      },
      {
        id: 5,
        name: "WebView",
        img: require("@/assets/main-screen/slider/wv-slide.png"),
        imgMob: require("@/assets/main-screen/slider/wv-slide-m.png"),
        description: "Сайт и QR-меню",
        isBigImage: true,
      },
    ],
    swiperOption: {
      spaceBetween: 50,
      loop: true,
      loopedSlides: 5,
      centeredSlides: true,
      slideToClickedSlide: true,
      initialSlide: 0,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet(index, className) {
          return `<span class="${className} swiper-pagination-bullet-custom"></span>`;
        },
      },
      breakpoints: {
        1350: {
          slidesPerView: 5,
          spaceBetween: 50,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 50,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
      },
    },
    width: 0,
    isMobileVersion: false,
  }),
  created() {
    window.addEventListener("resize", this.updateWidth);
    this.updateWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.updateWidth);
  },
  methods: {
    logItem(id) {
      console.log(id);
    },
    updateWidth() {
      this.width = window.innerWidth;
      this.width > 1000
        ? (this.isMobileVersion = false)
        : (this.isMobileVersion = true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.slider-container {
  padding: 115px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

h1 {
  width: 60%;
  font-size: 36px;
  font-weight: 600;
  line-height: 135%;
  text-align: center;
}

h2 {
  margin-top: 30px;
  width: 50%;
  font-size: 21px;
  font-weight: 400;
  text-align: center;
}

.swiper {
  width: 100%;
  height: 800px;
  margin-top: 40px;

  .big-image {
    img {
      width: unset;
    }
  }

  .swiper-pagination {
    top: 0px !important;
    bottom: unset;

    /deep/ .swiper-pagination-bullet-custom {
      width: 30px;
      height: 30px;
      opacity: 0.4;
      margin-right: 15px;
      margin-top: 5px;

      &:nth-child(1) {
        background: url("~@/assets/chat-bot-screen/inst-slide-icon.svg");
      }

      &:nth-child(2) {
        background: url("~@/assets/chat-bot-screen/tg-slide-icon.svg");
      }

      &:nth-child(3) {
        background: url("~@/assets/chat-bot-screen/vk-slide-icon.svg");
      }

      &:nth-child(4) {
        background: url("~@/assets/chat-bot-screen/fb-slide-icon.svg");
      }

      &:nth-child(5) {
        background: url("~@/assets/chat-bot-screen/wa-slide-icon.svg");
      }

      &:nth-child(6) {
        background: url("~@/assets/chat-bot-screen/qr-slide-icon.svg");
        margin-right: 0;
      }

      &:hover {
        opacity: 1;
      }

      &.swiper-pagination-bullet-active {
        opacity: 1;
      }
    }
  }
}

.swiper-slide {
  height: 100%;
  opacity: 0.5;
  transition: 0.3s;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  cursor: pointer;

  .btn-main-gradient {
    margin-top: 50px;
    display: none;
  }

  &:hover {
    opacity: 1;
  }

  img {
    margin-top: 120px;
    height: 490px;

    &.slide-inst {
      height: 505px;
      margin-top: 105px;
    }
  }

  span {
    margin-top: 25px;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  }

  &.swiper-slide-active {
    opacity: 1;
    z-index: 100;

    .btn-main-gradient {
      margin-top: 50px;
      display: inline-block;
    }

    &.big-image {
      img {
        width: unset;
      }
    }

    img {
      margin-top: 60px;

      &:slide-inst {
      }

      height: 545px;
    }
  }
}

.swiper-slide:nth-child(2n) {
  width: 40%;
}

.swiper-slide:nth-child(3n) {
  width: 20%;
}

.swiper-button {
  width: 26px;
  height: 22px;
  background: transparent;
  top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  use {
    color: $main-color;
  }

  &:hover {
    use {
      color: $green-color;
    }
  }

  &.swiper-button-prev {
    left: 35%;

    svg {
      transform: rotate(180deg);
    }
  }

  &.swiper-button-next {
    right: 35%;
  }

  svg {
    width: 14px;
    height: 12px;
  }
}

.bg-blur {
  width: 456px;
  height: 456px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(0, 145, 255, 0.25) 0%,
    rgba(0, 145, 255, 0) 100%
  );
  position: absolute;

  &.bg-blur-first {
    left: -60px;
    top: 40%;
  }

  &.bg-blur-second {
    right: 30%;
    top: 40%;
  }
}

@media (max-width: 435px) {
  .slider-container {
    padding: 30vw 0 50px 0 !important;
  }
}

@media (max-width: 1000px) {
  .slider-container {
    padding: 140px 0 50px 0;
  }
  h1 {
    width: 100%;
    font-size: 24px;
    padding: 0 15px;
  }
  h2 {
    width: 100%;
    font-size: 15px;
    padding: 0 30px;
    margin-top: 10px;
  }
  .swiper {
    padding: 0 40px;

    .swiper-wrapper {
      height: unset;
    }

    .swiper-pagination {
      left: 0;
    }
  }
  .swiper-slide {
    justify-content: flex-start;

    &.big-image {
      img {
        width: 100%;
      }
    }

    &.swiper-slide-active {
      .btn-main-gradient {
        margin-top: 35px;
      }
    }
  }
  .swiper-button {
    top: 32px;

    &.swiper-button-prev {
      left: 20px;
    }

    &.swiper-button-next {
      right: 20px;
    }
  }
  .bg-blur {
    display: none;
  }
}
</style>
