<template>
  <div id="footer" class="footer">
    <div class="footer__questions-block">
      <div class="question-block__text">
        <h1>Остались вопросы?</h1>
        <p>
          Задайте свой вопрос в форме и наши менеджеры свяжутся с вами в
          ближайшее время!
        </p>
        <p>
          Мы ответим на любые вопросы и расскажем, как внедрить DostavkinBot в
          работу вашего ресторана.
        </p>
      </div>
      <form
        id="feedback-form"
        action="#"
        method="POST"
        class="question-block__inputs"
      >
        <input
          type="hidden"
          name="partner_hash"
          id="partner_hash"
          :value="partner_hash"
        />
        <input
          type="text"
          class="_name"
          name="name"
          placeholder="Имя"
          required
        />
        <input
          type="text"
          class="_contacts"
          name="contacts"
          placeholder="E-mail или телефон"
          required
        />
        <textarea
          class="_text"
          name="text"
          placeholder="Что вас интересует?"
          required
        ></textarea>
        <button @click="formSend" type="submit" class="btn btn-green">
          Отправить
          <transition name="fade">
            <error-notice
              notice="Все поля необходимо заполнить!"
              v-if="errorForm"
            />
          </transition>
        </button>
        <img
          class="first-bg-icon"
          src="@/assets/footer/question1.svg"
          alt="footer-bg-icon"
        />
      </form>
      <img
        class="second-bg-icon"
        src="@/assets/footer/question2.svg"
        alt="footer-bg-icon"
      />
    </div>
    <div class="footer__bg-shadow"></div>
    <div class="footer__social-media">
      <router-link class="logo" to="/">
        <img src="@/assets/logo-white.svg" alt="logo-white-icon" />
      </router-link>
      <div class="links">
        <ul class="links-container">
          <router-link to="/">Главная</router-link>
          <router-link to="/personal-area">Личный кабинет</router-link>
          <a
            target="_blank"
            href="https://panel.dostavkinbot.online/dogovor-oferta.pdf"
            >Договор-оферта</a
          >
        </ul>
        <ul class="links-container">
          <router-link to="/chat-bot">Чат-боты и сайты</router-link>
          <router-link to="/tariffs">Тарифы</router-link>
          <a target="_blank" href="https://dostavkinbot.online/privacy.pdf"
            >Политика конфиденциальности</a
          >
        </ul>
      </div>
      <div class="links-social">
        <a
          v-for="link in socialLinks"
          target="_blank"
          :key="link.id"
          :href="link.url"
        >
          <img :src="link.icon" alt="social-icon" />
        </a>
      </div>
    </div>
    <transition name="fade">
      <send-form-success-modal
        @closeModal="closeModal"
        v-if="isSendFormSuccessModalOpen"
      />
    </transition>
  </div>
</template>

<script>
import jquery from "jquery";
import errorNotice from "../Base/error-notice.vue";
import SendFormSuccessModal from "./SendFormSuccessModal.vue";
const $ = jquery;

export default {
  components: { errorNotice, SendFormSuccessModal },
  data: () => ({
    formData: Object,
    partner_hash: "test_hash",
    socialLinks: [
      {
        id: 1,
        name: "fb",
        url: "https://www.facebook.com/Dostavkinbot.online",
        icon: require("@/assets/footer/fb-social.svg"),
      },
      {
        id: 2,
        name: "vk",
        url: "https://vk.com/dostavkinbot",
        icon: require("@/assets/footer/vk-social.svg"),
      },
      {
        id: 3,
        name: "yt",
        url: "https://www.youtube.com/channel/UCC1gnTVnsq5UtEXF1QSzluA",
        icon: require("@/assets/footer/youtube-social.svg"),
      },
      {
        id: 4,
        name: "inst",
        url: "https://www.instagram.com/dostavkinbot/",
        icon: require("@/assets/footer/insta-social.svg"),
      },
      {
        id: 5,
        name: "tg",
        url: "https://teleg.run/dostavkingroup",
        icon: require("@/assets/footer/tg-social.svg"),
      },
    ],
    errorForm: false,
    isSendFormSuccessModalOpen: false,
  }),
  methods: {
    formSend(e) {
      e.preventDefault();
      if (
        $("#feedback-form").find("._name").val() === "" ||
        $("#feedback-form").find("._contacts").val() === "" ||
        $("#feedback-form").find("._text").val() === ""
      ) {
        this.errorForm = true;
      } else {
        this.errorForm = false;
        this.formData = {
          name: $("#feedback-form").find("._name").val(),
          contacts: $("#feedback-form").find("._contacts").val(),
          text: $("#feedback-form").find("._text").val(),
          ref: $("#feedback-form").find("#partner_hash").val(),
        };
        $.ajax({
          url: "https://dostavkinbot.online/form.php",
          data: this.formData,
          method: "post",
        });
        this.isSendFormSuccessModalOpen = true;
      }
    },
    closeModal() {
      this.isSendFormSuccessModalOpen = false;
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.footer {
  background: $dark-purple-color;
  padding: 80px 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.footer__bg-shadow {
  width: 95%;
  height: 10px;
  background: linear-gradient(
    273.25deg,
    rgba(21, 109, 183, 0.3) 1.03%,
    rgba(88, 12, 213, 0.3) 98.93%
  );
  border-radius: 0 0 25px 25px;
}

.footer__questions-block {
  z-index: 1;
  width: 100%;
  background: linear-gradient(273.25deg, #156db7 1.03%, #580cd5 98.93%);
  border-radius: 25px;
  position: relative;
  padding: 65px 100px;
  display: flex;
}

.question-block__text {
  width: 50%;

  h1,
  p {
    color: $white-color;
  }

  h1 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 25px;
  }

  p {
    font-size: 21px;
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.question-block__inputs {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;

  .first-bg-icon {
    color: $main-color;
    width: 144px;
    height: 144px;
    right: 73%;
    top: 160px;
    mix-blend-mode: soft-light;
    transform: translateX(45%);
    z-index: 0;
  }

  input,
  textarea {
    width: 70%;
    background: $input-bg-color;
    border: 1px solid $input-bg-color;
    z-index: 1;
  }

  input {
    margin-top: 15px;

    &:first-child {
      margin-top: 0;
    }
  }

  textarea {
    margin-top: 15px;
    height: 105px;
  }

  .btn {
    margin-top: 30px;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
}

.first-bg-icon,
.second-bg-icon {
  position: absolute;
}

.second-bg-icon {
  top: -60px;
  right: -110px;
}

.footer__social-media {
  z-index: 1;
  margin-top: 80px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.links {
  display: flex;
  justify-content: space-between;

  ul {
    display: flex;
    flex-direction: column;

    &:first-child {
      margin-right: 50px;
    }

    a {
      color: $white-color;
      margin-bottom: 16px;

      &:hover {
        color: $green-color;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.links-social {
  a {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }
}

@media (max-width: 1000px) {
  .footer {
    padding: 90px 20px;
    .links {
      justify-content: unset;
      ul {
        width: 40%;
        a {
          width: unset;
        }
        &:first-child {
          margin-right: 20px;
          padding-left: 5%;
        }
      }
    }
    img.second-bg-icon {
      width: 130px;
      height: 130px;
      right: -50px;
      top: -35px;
    }

    .footer__bg-shadow {
      width: 80%;
    }

    form {
      ._name {
        margin-top: 0;
      }
    }

    .footer__questions-block {
      flex-direction: column;
      padding: 40px 30px;

      .question-block__text {
        width: 100%;

        h1 {
          font-size: 26px;
          font-weight: 600;
          text-align: center;
          margin-bottom: 15px;
        }

        p {
          font-size: 15px;
          text-align: center;
          font-weight: 300;
        }
      }

      .question-block__inputs {
        margin-top: 30px;
        width: 100%;

        input,
        textarea,
        .btn-green {
          width: 100%;
        }
      }
    }

    .footer__social-media {
      margin-top: 35px;
      flex-direction: column;

      .links {
        width: 100%;
        justify-content: center;

        a {
          font-size: 14px;
        }
      }

      .links-social {
        margin-top: 60px;
        width: 100%;
        display: flex;
        justify-content: center;

        a {
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .logo {
      display: none;
    }
  }
}
</style>
