<template>
  <div class="container-fluid">
    <div class="banner">
      <div class="banner-text">
        <h1>Возможности для сетей заведений</h1>
        <img
          v-if="isMobileVersion"
          class="banner__background"
          src="@/assets/chat-bot-screen/banner/mobile-background-banner.png"
          alt=""
        />
        <h2>У вас не одно заведение, а сеть из нескольких?</h2>
        <div class="banner-items">
          <div v-for="(item, index) in bannerItems" :key="index" class="item">
            <img src="@/assets/okay-icon.svg" alt="okay-icon" />
            {{ item }}
          </div>
        </div>
      </div>
      <button @click="scrollDown" class="btn btn-blue">Оставить заявку</button>
      <img
        v-if="!isMobileVersion"
        class="banner__background"
        src="@/assets/chat-bot-screen/banner/background-banner.png"
        alt="background-icon"
      />
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    bannerItems: [
      "Добавляйте все ваши заведения в один чат-бот",
      "Уведомления о заказе будет получать тот личный кабинет, который соответствует этому заведению",
      "Возможность индивидуальной разработки",
    ],
    width: 0,
    isMobileVersion: false,
  }),
  created() {
    window.addEventListener("resize", this.updateWidth);
    this.updateWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.updateWidth);
  },
  methods: {
    scrollDown() {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    },
    updateWidth() {
      this.width = window.innerWidth;
      this.width > 1000
        ? (this.isMobileVersion = false)
        : (this.isMobileVersion = true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.container-fluid {
  padding: 0 140px;
}

.banner {
  padding: 50px 70px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  background: $purple-color;
  border-radius: 10px;
  position: relative;
}

h1 {
  color: $white-color;
  font-size: 30px;
  font-weight: 600;
}

h2 {
  color: $white-color;
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
}

.banner-items {
  margin-top: 10px;

  .item {
    font-family: Montserrat;
    font-size: 15px;
    color: $white-color;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.banner-text {
  width: 50%;
  z-index: 100;
}

.btn-blue {
  margin-top: 30px;
  font-size: 18px;
}

.banner__background {
  height: 100%;
  position: absolute;
  bottom: 0;
  right: -10px;
  z-index: 0;
}

@media (max-width: 1000px) {
  .container-fluid {
    padding: 0 15px;
  }
  .banner {
    background: $main-screen-bg-color;
    padding: 30px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
  }
  .banner-items {
    .item {
      color: $black-color;
      text-align: center;
    }
  }

  .banner__background {
    position: unset;
    width: 100%;
    margin-top: 15px;
  }
  .banner-text {
    width: 100%;
  }
  h1,
  h2 {
    color: $black-color;
    text-align: center;
  }
  h1 {
    font-size: 24px;
  }
  h2 {
    margin-top: 30px;
    font-size: 18px;
  }
}
</style>
