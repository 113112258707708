<template>
  <div class="enterprise-container">
    <div class="enterprise-content">
      <span class="enterprise-title">Интерпрайз</span>
      <img class="enterprise-image" src="@/assets/tariffs/enterprise-bg.png" alt="enterprise-image">
      <div class="enterprise-text">
        <h1>Для СЕТЕЙ ЗАВЕДЕНИЙ у нас есть особое предложение: </h1>
        <div class="offer" v-for="(offer, index) in offers" :key="index">
          <img src="@/assets/okaygreen.svg" alt="okay-icon">
          <span>{{ offer }}</span>
        </div>
        <button @click="scrollDown" class="btn btn-green">Оставить заявку</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    offers: [
      'Все заведения в едином боте',
      'Единый личный кабинет для всех заведений',
      'Возможность индивидуальной разработки'
    ]
  }),
  methods: {
    scrollDown() {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth"
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.enterprise-container {
  margin-top: 40px;
  padding: 0 140px;
}

.enterprise-content {
  width: 100%;
  min-height: 330px;
  border-radius: 10px;
  border: 1px solid $tariffs-item-non-active-color;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
}

.enterprise-title {
  z-index: 10;
  position: absolute;
  left: 25px;
  top: 20px;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  color: $main-color;
  font-weight: 600;
}

.enterprise-image {
  height: 100%;
  position: absolute;
  bottom: -10px;
  left: 0;
}

.enterprise-text {
  width: 50%;
  height: 100%;
  padding: 40px 0 45px 0;
  z-index: 10;

  h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .offer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    img {
      width: 12px;
      height: 9px;
      margin-right: 5px;
    }

    span {
      font-family: 'Montserrat';
      font-size: 14px;
      line-height: 155%;
    }
  }

  .btn {
    margin-top: 25px;
    font-size: 18px;
  }
}

@media (max-width: 1000px) {
  .enterprise-container {
    padding: 0 20px;
    margin-top: 30px;

    .enterprise-content {
      display: flex;
      flex-direction: column;
    }

    .enterprise-image {
      width: 100%;
      position: unset;
      height: unset;
      max-height: 250px;
    }

    .enterprise-text {
      width: 100%;
      padding: 20px;

      .offer {
        align-items: flex-start;

        img {
          margin-top: 5px;
        }
      }

      h1 {
        font-size: 24px;
      }

      .btn-green {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 30px;
      }
    }
  }
}
</style>