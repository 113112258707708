<template>
  <div class="discount-container">
    <span>-{{ discount }}%</span>
  </div>
</template>

<script>
export default {
  props: ['discount'],
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.discount-container {
  margin-left: 5px;
  width: 30px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background: url("~@/assets/tariffs/discount-rect.svg");
}

span {
  color: $white-color;
  font-size: 9px;
  font-weight: 600;
  line-height: 0;
}
</style>