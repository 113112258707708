<template>
  <a class="tasks-item">
    <div class="img-container">
      <img :src="item.img" alt="task-image">
    </div>
    <span class="tasks-item__description">
      {{ item.description }}
    </span>
    <!--    next version-->
    <!--    <svg class="circle-arrow">-->
    <!--      <use href="@/assets/circle-arrow.svg#circle-arrow"></use>-->
    <!--    </svg>-->
  </a>
</template>

<script>
export default {
  props: ['item']
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.tasks-item {
  width: 33.33%;
  padding: 0 20px;
  //cursor: pointer;
  position: relative;

  &:nth-child(n+4):nth-child(-n+9) {
    margin-top: 40px;
  }
}

.img-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  border-radius: 5px;
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  img {
    width: 100%;
    height: 100%;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}

.tasks-item__description {
  width: 70%;
  position: absolute;
  top: 20px;
  left: 45px;
  font-size: 20px;
  font-weight: 700;
  color: $white-color;
}

svg {
  width: 26px;
  height: 26px;
  position: absolute;
  left: 45px;
  bottom: 20px;

  use {
    color: $white-color;
  }
}
</style>