<template>
  <div class="work-step">
    <div class="step-icon-container">
      <img :src="item.icon" alt="step-icon" />
      <div class="step-num">
        {{ item.id }}
      </div>
    </div>
    <div class="work-step__text">
      <span class="item-header">{{ item.name }}</span>
      <span class="item-description">{{ item.description }}</span>
      <a
        v-if="item.linkName === 'Зарегистрироваться'"
        href="https://panel.dostavkinbot.online/register"
      >
        {{ item.linkName }}
        <svg class="arrow-icon">
          <use href="@/assets/arrow.svg#arrow-icon"></use>
        </svg>
      </a>
      <router-link v-else :to="item.url">
        {{ item.linkName }}
        <svg class="arrow-icon">
          <use href="@/assets/arrow.svg#arrow-icon"></use>
        </svg>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data: () => ({
    width: 0,
    isMobileVersion: false,
  }),
  created() {
    window.addEventListener("resize", this.updateWidth);
    this.updateWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.updateWidth);
  },
  methods: {
    updateWidth() {
      this.width = window.innerWidth;
      this.width > 1000
        ? (this.isMobileVersion = false)
        : (this.isMobileVersion = true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.work-step {
  width: 33.33%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-icon-container {
  width: 100px;
  height: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white-color;
  border-radius: 30px;
  box-shadow: 0px 20px 30px rgba(1, 52, 40, 0.1);
}

.step-num {
  width: 22px;
  height: 22px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: -5px;
  border-radius: 50%;
  background: $main-color;
  font-size: 14px;
  font-weight: 700;
  color: $white-color;
}

.work-step__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;

  span {
    text-align: center;

    &.item-header {
      font-size: 21px;
      font-weight: 600;
    }

    &.item-description {
      font-size: 18px;
      font-weight: 400;
      margin-top: 5px;
    }
  }

  a {
    margin-top: 20px;
    font-size: 14px;
    color: $main-color;
    border-bottom: 1px solid $main-color;
    text-align: center;
    &:hover {
      color: $black-color;
      border-bottom: 1px solid $black-color;

      svg {
        margin-left: 10px;

        use {
          color: $black-color;
        }
      }
    }
  }
}

.arrow-icon {
  width: 11px;
  height: 8px;
  margin-left: 5px;

  use {
    color: $main-color;
  }
}
@media (max-width: 1480px) {
  .work-step__text {
    a {
      border-bottom: 0;
    }
  }
}
@media (max-width: 1000px) {
  .work-step__text a {
    font-size: 12px;
    text-align: left;
  }
  .work-step {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    .step-icon-container {
      max-width: 68px;
      max-height: 68px;
      min-width: 68px;
      min-height: 68px;
      border-radius: 20px;
      margin-right: 20px;
    }
  }
  .work-step__text {
    align-items: flex-start;
    margin-top: 0;

    span.item-header {
      text-align: left;
      font-size: 18px;
    }

    span.item-description {
      width: 100%;
      text-align: left;
      font-size: 15px;
    }
  }
}
</style>
