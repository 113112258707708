<template>
  <div class="container-fluid">
    <div class="start-work-content">
      <h1>Как начать работать с DostavkinBot?</h1>
      <div class="start-work__steps">
        <img
          v-if="isMobileVersion"
          src="@/assets/main-screen/start-work/start-work-steps-mobile.png"
          alt="bg-steps-image"
        />
        <img
          v-else
          src="@/assets/main-screen/start-work/start-work-steps.png"
          alt="bg-steps-image"
        />
        <work-step v-for="item in stepsItems" :key="item.id" :item="item" />
      </div>
      <a
        href="https://panel.dostavkinbot.online/register"
        class="btn btn-main-gradient"
        >Начать сейчас</a
      >
    </div>
  </div>
</template>

<script>
import WorkStep from "./WorkStep";

export default {
  components: { WorkStep },
  data: () => ({
    stepsItems: [
      {
        id: 1,
        name: "Зарегистрируйтесь и загрузите своё меню в личном кабинете",
        description:
          "синхронизировав личный кабинет с вашей CRM, либо через интерфейс личного кабинета",
        url: "https://panel.dostavkinbot.online/register",
        linkName: "Зарегистрироваться",
        icon: require("@/assets/main-screen/start-work/work-step-1.svg"),
      },
      {
        id: 2,
        name: "Выберите каналы продаж и получите готовые решения",
        description:
          "для автоматического приема заказов в мессенджерах и соцсетях",
        url: "/chat-bot",
        linkName: "Активируйте нужные каналы продаж в пару кликов",
        icon: require("@/assets/main-screen/start-work/work-step-2.svg"),
      },
      {
        id: 3,
        name: "Принимайте и обрабатывайте заказы в личном кабинете",
        description:
          "следите за статистикой, делайте рассылки, общайтесь с клиентами",
        url: "/personal-area",
        linkName: "Принимайте заказы из удобных для гостей каналов",
        icon: require("@/assets/main-screen/start-work/work-step-3.svg"),
      },
    ],
    width: 0,
    isMobileVersion: false,
  }),
  created() {
    window.addEventListener("resize", this.updateWidth);
    this.updateWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.updateWidth);
  },
  methods: {
    updateWidth() {
      this.width = window.innerWidth;
      this.width > 1000
        ? (this.isMobileVersion = false)
        : (this.isMobileVersion = true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.container-fluid {
  padding: 0 30px;
}

.start-work-content {
  background: linear-gradient(0deg, #f0f4ff, #f0f4ff);
  border-radius: 20px;
  padding: 140px 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  font-size: 36px;
  line-height: 135%;
  font-weight: 600;
  text-align: center;
}

.start-work__steps {
  width: 100%;
  margin-top: 100px;
  display: flex;
  position: relative;
  margin-left: -20px;
  margin-right: -20px;

  img {
    width: 65%;
    height: 140px;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }
}

.btn-main-gradient {
  margin-top: 60px;
  display: inline-block;
}

@media (max-width: 1000px) {
  .container-fluid {
    padding: 10px;
  }
  .start-work__steps {
    flex-direction: column;
    margin-top: 30px;
    margin-left: 0;
    margin-right: 0;

    img {
      width: unset;
      height: 65%;
      left: 20px;
      top: 45%;
      transform: translateY(-50%);
    }
  }
  .btn-main-gradient {
    margin-top: 40px;
  }
  .start-work-content {
    padding: 55px 25px;

    .btn {
      display: none;
    }

    h1 {
      font-size: 24px;
      line-height: 140%;
    }
  }
}
</style>
