<template>
  <div class="container-fluid">
    <h1>Возможности личного кабинета</h1>
    <swiper v-if="isMobileVersion" class="swiper" :options="swiperOption">
      <swiper-slide v-for="feature in features" :key="feature.id">
        <a>
          <img :src="feature.img" alt="slide-image" />
          <span class="feature-slide-description">{{
            feature.description
          }}</span>
        </a>
      </swiper-slide>
      <div
        class="swiper-pagination swiper-pagination-bullets"
        slot="pagination"
      ></div>
      <div class="swiper-button swiper-button-prev" slot="button-prev">
        <svg class="arrow-icon">
          <use href="@/assets/arrow.svg#arrow-icon"></use>
        </svg>
      </div>
      <div class="swiper-button swiper-button-next" slot="button-next">
        <svg class="arrow-icon">
          <use href="@/assets/arrow.svg#arrow-icon"></use>
        </svg>
      </div>
    </swiper>
    <div v-else class="features-list">
      <features-item
        v-for="feature in features"
        :key="feature.id"
        :item="feature"
      />
    </div>
  </div>
</template>

<script>
import FeaturesItem from "../../PersonalArea/Features/FeaturesItem";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  components: { FeaturesItem, Swiper, SwiperSlide },
  data: () => ({
    features: [
      {
        id: 1,
        description: "Эффективная работа с базой гостей",
        img: require("@/assets/personal-area/features/features1.jpg"),
      },
      {
        id: 2,
        description: "Гибкая настройка под \n любой тип заведения",
        img: require("@/assets/personal-area/features/features2.jpg"),
      },
      {
        id: 3,
        description: "Аналитика потерянных клиентов и инструменты для возврата",
        img: require("@/assets/personal-area/features/features3.jpg"),
      },
      {
        id: 4,
        description: "Все клиенты в едином окне",
        img: require("@/assets/personal-area/features/features4.jpg"),
      },
      {
        id: 5,
        description: "Синхронизация с CRM и платежными системами",
        img: require("@/assets/personal-area/features/features5.jpg"),
      },
      {
        id: 6,
        description: "Решение для сетей заведений",
        img: require("@/assets/personal-area/features/features6.jpg"),
      },
    ],
    swiperOption: {
      slidesPerView: 1,
      spaceBetween: 10,
      centeredSlides: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet(index, className) {
          return `<span class="${className} swiper-pagination-bullet-custom"></span>`;
        },
      },
    },
    width: 0,
    isMobileVersion: false,
  }),
  created() {
    window.addEventListener("resize", this.updateWidth);
    this.updateWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.updateWidth);
  },
  methods: {
    updateWidth() {
      this.width = window.innerWidth;
      this.width > 1000
        ? (this.isMobileVersion = false)
        : (this.isMobileVersion = true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.container-fluid {
  padding: 120px 140px 100px 140px;
}

.swiper {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;

  .swiper-pagination {
    display: flex;
    justify-content: flex-start;
    padding-left: 20px;
    bottom: 22px;
  }
}

.swiper-button {
  width: 44px;
  height: 44px;
  background: transparent;
  border: 1px solid $main-color;
  border-radius: 50%;
  top: unset;
  bottom: 5px;

  use {
    color: $main-color;
  }

  &:hover {
    background: $main-color;

    use {
      color: $white-color;
    }
  }

  &.swiper-button-prev {
    right: 80px;
    left: unset;

    svg {
      transform: rotate(180deg);
    }
  }

  &.swiper-button-next {
    right: 20px;
  }

  svg {
    width: 14px;
    height: 12px;
  }
}

.swiper-slide {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;

  a {
    width: 100%;
  }

  img {
    width: 100%;
    border-radius: 10px;
  }

  span {
    position: absolute;
    top: 20px;
    left: 20px;
    color: $white-color;
    font-weight: 700;
  }

  &:last-child {
    .feature-slide-description {
      color: $purple-color;
    }
  }
}

h1 {
  font-size: 36px;
  font-weight: 600;
  text-align: center;
}

.features-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-left: -20px;
  margin-right: -20px;
}

@media (max-width: 435px) {
  .container-fluid {
    padding: 10vw 0 70px 0 !important;
  }
}

@media (max-width: 1000px) {
  .container-fluid {
    padding: 90px 0 70px 0;
  }
  h1 {
    font-size: 30px;
    padding: 0 20px;
  }
  .swiper {
    padding: 0 15px;
    margin-top: 25px;

    .swiper-slide {
      &:first-child {
        .feature-slide-description {
          width: 90%;
          font-size: 20px;
        }
      }

      &:nth-child(2) {
        .feature-slide-description {
          width: 70%;
          font-size: 20px;
        }
      }

      &:nth-child(3) {
        .feature-slide-description {
          width: 70%;
          font-size: 20px;
        }
      }

      &:nth-child(4) {
        .feature-slide-description {
          width: 50%;
          font-size: 20px;
        }
      }

      &:nth-child(5) {
        .feature-slide-description {
          width: 90%;
          font-size: 20px;
        }
      }

      &:nth-child(6) {
        .feature-slide-description {
          width: 70%;
          font-size: 20px;
        }
      }
    }
  }
  .feature-slide-description {
    font-size: 20px;
  }
  .features-list {
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
