import { render, staticRenderFns } from "./Tasks.vue?vue&type=template&id=46db9bf9&scoped=true&"
import script from "./Tasks.vue?vue&type=script&lang=js&"
export * from "./Tasks.vue?vue&type=script&lang=js&"
import style0 from "./Tasks.vue?vue&type=style&index=0&id=46db9bf9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46db9bf9",
  null
  
)

export default component.exports