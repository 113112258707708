<template>
  <div @click.self="closeVideoModal" class="modal-overlay">
    <div class="modal-content">
      <div class="modal-header">
        <img
          @click="closeVideoModal"
          class="close-modal-icon"
          src="@/assets/chat-bot-screen/video-buttons/union.svg"
          alt="close-icon"
        />
      </div>

      <div class="modal-body">
        <iframe
          id="ytplayer"
          type="text/html"
          :src="currentVideoData.video.link"
          frameborder="0"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["currentVideoData"],
  methods: {
    closeVideoModal() {
      this.$emit("closeVideoModal");
    },
  },
  mounted() {
    setTimeout(() => {
      let yt = document.getElementsByClassName(
        "ytp-cued-thumbnail-overlay-image"
      )[0];
      console.log(yt);
      yt.setAttribute("style", "background-size: 0");
    }, 3000);
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
}
.modal-content {
  width: 640px;
  height: 360px;
  object-fit: contain;
  margin: auto;
  border-radius: 15px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  background: $white-color;
  position: relative;
}
.modal-body {
  height: 100%;
  object-fit: contain;
  iframe {
    border-radius: 15px;
    width: 100%;
    height: 100%;
  }
}
.close-modal-icon {
  width: 14px;
  height: 14px;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  transition: 0.1;
  &:hover {
    transform: scale(1.3);
  }
}

@media (max-width: 768px) {
  .modal-content {
    width: 100%;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    iframe {
      left: 0;
      top: 0;
      position: absolute;
      z-index: 1000;
    }
  }
  .modal-overlay {
    padding: 0 20px;
  }
}
</style>
