<template>
  <div class="container-fluid">
    <h1>Клиенты DostavkinBot</h1>
    <h2>
      Не важно, сколько заказов вы обрабатываете за день. Dostavkinbot подходит
      всем.
    </h2>
    <div class="clients-icons-container">
      <img
        v-if="isMobileVersion"
        class="clients-icon-bg"
        src="@/assets/main-screen/clients/clients-bg-mobile.png"
        alt="clients-bg"
      />
      <img
        v-else
        class="clients-icon-bg"
        src="@/assets/main-screen/clients/clients-bg.png"
        alt="clients-bg"
      />
      <div class="clients-banner-container">
        <div class="clients-banner">
          <span class="client-banner__count">600+</span>
          <span class="client-banner__description"
            >самых разных ресторанов <br />
            и сетей уже работают с нами</span
          >
          <div class="clients-banner__img-container"></div>
        </div>
        <img
          class="banner-img-bg"
          src="@/assets/main-screen/clients/clients-banner-bg.png"
          alt="banner-img"
        />
      </div>
    </div>
    <div class="bg-blur-shadow"></div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isMobileVersion: false,
    isDropdownOpen: false,
    width: 0,
  }),
  created() {
    window.addEventListener("resize", this.updateWidth);
    this.updateWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.updateWidth);
  },
  methods: {
    updateWidth() {
      this.width = window.innerWidth;
      this.width > 1000
        ? (this.isMobileVersion = false)
        : (this.isMobileVersion = true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.container-fluid {
  padding: 150px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.bg-blur-shadow {
  width: 100%;
  height: 450px;
  position: absolute;
  bottom: 0;
  z-index: 40;
  background: linear-gradient(
    185.25deg,
    rgba(239, 246, 255, 0) -4.66%,
    rgba(239, 246, 255, 0.78) 40%,
    #eff6ff 100%,
    rgba(239, 246, 255, 0) 95.23%
  );
}

h1 {
  font-size: 36px;
  font-weight: 600;
  line-height: 135%;
  text-align: center;
}

h2 {
  width: 50%;
  font-size: 21px;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.clients-icons-container {
  width: 100%;
  margin-top: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

img.clients-icon-bg {
  width: 100%;
  margin-bottom: 140px;
}

.clients-banner-container {
  width: 80%;
  height: 235px;
  position: absolute;
  bottom: 0;
  z-index: 100;
}

.clients-banner {
  width: 100%;
  height: 100%;
  padding: 30px 65px;
  border-radius: 30px;
  background: linear-gradient(0deg, #ffffff, #ffffff), #0091ff;
  box-shadow: 0px 20px 30px #dae1f5;
  display: flex;
  align-items: center;
  position: relative;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  overflow: hidden;
}

.client-banner__count {
  font-size: 100px;
  font-weight: 700;
  background: linear-gradient(93.45deg, #0091ff 9.65%, #16c774 96.55%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-right: 20px;
}

.client-banner__description {
  font-size: 30px;
  font-weight: 600;
  line-height: 135%;
}

.clients-banner__img-container {
  width: 600px;
  height: 600px;
  background: linear-gradient(93.45deg, #0091ff 9.65%, #16c774 96.55%);
  position: absolute;
  top: -80px;
  right: -200px;
  border-radius: 50%;
}

img.banner-img-bg {
  width: 440px;
  position: absolute;
  right: -20px;
  bottom: 0;
}

@media (max-width: 1000px) {
  .container-fluid {
    padding: 110px 15px 0px 15px;
  }

  h1 {
    font-size: 24px;
  }
  h2 {
    width: 100%;
    margin-top: 10px;
    font-size: 15px;
  }
  img.clients-icon-bg {
    width: 100%;
    margin-bottom: 0;
  }
  .clients-icons-container {
    margin-top: 40px;
  }
  .clients-banner-container {
    width: 100%;
    position: unset;
    margin-top: 30px;
    height: 570px;
    margin-bottom: 30px;
  }
  .clients-banner {
    padding: 20px 15px;
    flex-direction: column;
    align-items: center;

    .client-banner__description {
      font-size: 24px;
      text-align: center;
    }

    .client-banner__count {
      font-size: 80px;
      margin-right: 0;
    }

    .clients-banner__img-container {
      width: 420px;
      height: 420px;
      left: -10px;
      top: unset;
      bottom: -160px;
    }
  }
  img.banner-img-bg {
    height: 350px;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
  }
}
</style>
