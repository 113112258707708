<template>
  <div class="opportunities-list">
    <opportunities-item
      v-for="(item, index) in opportunitiesItems"
      :key="index"
      :item="item"
    />
  </div>
</template>

<script>
import OpportunitiesItem from "./OpportunitiesItem";

export default {
  components: { OpportunitiesItem },
  data: () => ({
    opportunitiesItems: [
      {
        header: "Прием и обработка заказов",
        features: [
          "Заказы с подключенных мессенджеров попадают в единый личный кабинет, где отображается детальная информация о заказах.",
          "О каждом новом заказе вам на телефон приходит уведомление в бот-ассистент",
        ],
        img: {
          desktop: require("@/assets/personal-area/opportunities/op1.png"),
          mobile: require("@/assets/personal-area/opportunities/op1-mobile.png"),
        },
      },
      {
        header: "Единая база клиентов",
        infoHeader: "Просматривайте подробную информацию о каждом клиенте:",
        info: [
          "Имя",
          "Телефон",
          "Когда присоединился",
          "Из какого менеджера гость оставил заказ",
          "История отзывов гостей и т.д.",
        ],
        socialsHeader: "Общайтесь в едином окне с клиентами из:",
        socials: [
          {
            name: "Instagram",
            img: require("@/assets/personal-area/opportunities/inst-slide-icon.svg"),
          },
          {
            name: "Vkontakte",
            img: require("@/assets/personal-area/opportunities/vk-slide-icon.svg"),
          },
          {
            name: "Facebook",
            img: require("@/assets/personal-area/opportunities/fb-slide-icon.svg"),
          },
          {
            name: "WhatsApp",
            img: require("@/assets/personal-area/opportunities/wa-slide-icon.svg"),
          },
          {
            name: "Telegram",
            img: require("@/assets/personal-area/opportunities/tg-slide-icon.svg"),
          },
          {
            name: "С сайта",
            img: require("@/assets/personal-area/opportunities/qr-slide-icon.svg"),
          },
        ],
        img: {
          desktop: require("@/assets/personal-area/opportunities/op2.png"),
          mobile: require("@/assets/personal-area/opportunities/op2-mobile.png"),
        },
      },
      {
        header: "Интеграции",
        featuresHeader: "Личный кабинет возможно интегрировать:",
        features: [
          "С POS системами: iiko, r-keeper (скоро), poster, frontpad",
          "С платежными системами для онлайн оплаты: Apple Pay, Google Pay, Samsung Pay, ЮКасса, Robokassa, Click, Paybox, Sber, Tinkoff",
        ],
        img: {
          desktop: require("@/assets/personal-area/opportunities/op3.png"),
          mobile: require("@/assets/personal-area/opportunities/op3-mobile.png"),
        },
      },
      {
        header: "Гибкие настройки доставки",
        socialsHeader:
          "Все зоны доставки синхронизируются с учетными системами:",
        socials: [
          {
            name: "Iiko",
            img: require("@/assets/personal-area/opportunities/iiko-icon.svg"),
          },
          {
            name: "Poster",
            img: require("@/assets/personal-area/opportunities/poster-icon.svg"),
          },
          {
            name: "Frontpad",
            img: require("@/assets/personal-area/opportunities/frontpad-icon.svg"),
          },
          {
            name: "r-keeper",
            img: require("@/assets/personal-area/opportunities/keeper-icon.svg"),
          },
        ],
        features: [
          "Настройте радиус доставки и разбейти его на зоны",
          "Задайте стоимость доставки в определенной зоне",
          "Уточните условия доставки при определенном чеке",
        ],
        img: {
          desktop: require("@/assets/personal-area/opportunities/op4.png"),
          mobile: require("@/assets/personal-area/opportunities/op4-mobile.png"),
        },
      },
      {
        header: "Анализируйте клиентов и их заказы",
        featuresHeader:
          "Все собранные данные можно использовать для аналитики:",
        features: [
          "Эффективности рекламы",
          "Потери клиентов",
          "Финансовых показателей",
          "Интересов клиентов",
          "Обратной связи клиентов",
        ],
        img: {
          desktop: require("@/assets/personal-area/opportunities/op5.png"),
          mobile: require("@/assets/personal-area/opportunities/op5-mobile.png"),
        },
      },
      {
        header: "Создавайте бесплатные рассылки",
        headerCounts:
          "В нашем личном кабинете возможно создать 3 типа рассылок:",
        counts: [
          {
            id: 1,
            description: {
              boldText: "Рекламное сообщение",
              normalText: "для оповещения о новостях",
            },
          },
          {
            id: 2,
            description: {
              boldText: "Опросы",
              normalText: "с отслеживанием статистики по ответам",
            },
          },
          {
            id: 3,
            description: {
              boldText: "Акционное предложение",
              normalText: "с возможностью заказа",
            },
          },
        ],
        footerCounts: "Рассылку возможно настроить на разные типы клиентов",
        img: {
          desktop: require("@/assets/personal-area/opportunities/op6.png"),
          mobile: require("@/assets/personal-area/opportunities/op6-mobile.png"),
        },
      },
      {
        header: "Собирайте отзывы о заказах",
        description: [
          "Все отзывы собираются в одном окне. Из таблицы с отзывами можно перейти в заказ и в профиль клиента.",
          "На каждый плохой отзыв мы можете вовремя отреагировать. Узнать причину негативного отзыва, выслать промокод или индивидуальное предложение.",
          "А позитивные отзывы можно дублировать на популярных плозадках (2GIS, Яндекс карты, Google maps)",
        ],
        img: {
          desktop: require("@/assets/personal-area/opportunities/op7.png"),
          mobile: require("@/assets/personal-area/opportunities/op7-mobile.png"),
        },
      },
      {
        header: "Создавайте промокоды",
        description: [
          "Промокоды - неотъемлемая часть программы лояльности Вы можете создавать разные промокоды для новых и текущих клиентов, которые можно будет ввести при оформлении заказа.",
        ],
        headerCounts: "Промокоды бывают 2 типов:",
        counts: [
          {
            id: 1,
            description: {
              boldText: "Скидочные",
              normalText:
                " - такой промокод дает указанную вами скидку в % на общик чек;",
            },
          },
          {
            id: 2,
            description: {
              boldText: "Подарочный",
              normalText:
                " - при вводе промокода клиент получит указанный вами подарок при оформлении заказа, к примеру, десерт или бесплатную доставку",
            },
          },
        ],
        img: {
          desktop: require("@/assets/personal-area/opportunities/op8.png"),
          mobile: require("@/assets/personal-area/opportunities/op8-mobile.png"),
        },
      },
      {
        header: "UTM метки (Уникальные ссылки)",
        description: [
          "Больше не нужны отдельные сервисы для создания уникальных ссылок.",
        ],
        featuresHeader: "Отслеживайте:",
        features: [
          "Общее количество переходов",
          "Уникальное количество переходов",
          "Конкретных клиентов, перешедших по ссылкам",
          "Сегментируйте клиентов на группы, в зависимости от того, по какой ссылке они перешли",
        ],
        img: {
          desktop: require("@/assets/personal-area/opportunities/op9.png"),
          mobile: require("@/assets/personal-area/opportunities/op9-mobile.png"),
        },
      },
      {
        header: "Создавайте Super link",
        description: [
          "Разместите все свои каналы продаж, любые другие ссылки и информацию о вас на гиперлинке для удобства ваших клиентов.",
          "Переходя с рекламы или из шапки соц.сетей, клиент сможет выбрать удобный способ взаимодействия с вами.",
        ],
        img: {
          desktop: require("@/assets/personal-area/opportunities/op10.png"),
          mobile: require("@/assets/personal-area/opportunities/op10-mobile.png"),
        },
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.opportunities-list {
  display: flex;
  flex-direction: column;
}
</style>
