<template>
  <div class="features-item">
    <div class="features-item__content">
      <div class="feature-item__text">
        <div class="features-item__header">
          <pre>{{ item.header.name }}</pre>
          <div class="header__img-container">
            <img :src="item.header.image" alt="feature-item-img">
          </div>
        </div>
        <div v-if="item.profits" class="features-item__profits">
          <div v-for="(itemProfit, index) in item.profits" :key="index" class="profit-item">
            <img src="@/assets/okay-icon-circle.svg" alt="okay-circle-icon">
            {{ itemProfit }}
          </div>
        </div>
      </div>
      <div v-if="item.notice" class="feature-item__footer">
        <div class="item-notice-container">
          <img src="@/assets/chat-bot-screen/features/features1.png" alt="notice-icon">
          <span>{{ item.notice }}</span>
        </div>
      </div>
    </div>
    <div class="features-item-bg"></div>
  </div>
</template>

<script>
export default {
  props: ['item']
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.features-item {
  margin-top: 40px;
  padding: 0 20px;
  width: 33.33%;
  position: relative;

  &:first-child {
    .header__img-container {
      img {
        width: 100px;
        height: 104px;
      }
    }
  }

  &:nth-child(2) {
    .header__img-container {
      img {
        width: 91px;
        height: 84px;
      }
    }
  }

  &:nth-child(3) {
    .header__img-container {
      img {
        width: 74px;
        height: 82px;
      }
    }
  }

  &:nth-child(4) {
    .header__img-container {
      img {
        width: 80px;
        height: 90px;
      }
    }
  }

  &:nth-child(5) {
    .header__img-container {
      img {
        width: 103px;
        height: 94px;
      }
    }
  }

  &:last-child {
    .header__img-container {
      img {
        width: 120px;
        height: 120px;
      }
    }
  }
}

.features-item__content {
  width: 100%;
  height: 100%;
  padding: 20px;
  background: $white-color;
  box-shadow: 0px 5px 40px rgba(218, 225, 245, 0.8);
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 100;
}

.features-item-bg {
  width: 90%;
  height: 20px;
  position: absolute;
  background: $white-color;
  box-shadow: 0px 5px 40px rgba(218, 225, 245, 0.8);
  border-radius: 10px;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.sends__item {
  font-size: 14px;
  margin-top: 10px;
}

.features-item__header {
  font-size: 21px;
  min-height: 90px;
  font-weight: 600;
  position: relative;
}

.header__img-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: $sky-color;
  display: flex;
  align-items: center;
  justify-content: center;
}

.features-item__profits {
  margin-top: 25px;

  .profit-item {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    font-size: 14px;

    img {
      margin-top: 3px;
      margin-right: 5px;
    }

  }
}


.feedbacks__item {
  margin-top: 10px;

  span {
    font-size: 14px;

    &:first-child {
      font-weight: 700;
    }
  }
}

.feature-item__footer {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  flex-direction: column;

  .item-notice-container {
    display: flex;
    align-items: flex-start;
    margin-top: 15px;

    img {
      width: 14px;
      height: 14px;
      margin-top: 3px;
      margin-right: 5px;
    }
  }

}

@media (max-width: 1000px) {
  .features-item {
    width: 100%;
    margin-top: 20px;
    padding: 0;
    min-height: unset;
  }
}
</style>