<template>
  <div class="banner-container">
    <h1>Базовый функционал, доступный на всех тарифах:</h1>
    <div class="base-functions-list">
      <banner-item v-for="(baseFunction, index) in baseFunctions" :key="index" :baseFunction="baseFunction"/>
    </div>
    <img class="bg-image" src="@/assets/tariffs/bg-image.png" alt="bg-image">
  </div>
</template>

<script>
import BannerItem from "./BannerItem";

export default {
  components: {BannerItem},
  data: () => ({
    baseFunctions: [
      'Прием и обработка заказов',
      'Бесплатные рассылки',
      'Система промокодов',
      'Создание уникальных ссылок ',
      'Уведомления о статусе заказа',
      'Сбор отзывов и обратная связь',
      'Super link (мультиссылка)',
      'Чаты с гостями',
      'Онлайн оплата',
      'Поддержка 24/7'
    ]
  })
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.banner-container {
  margin-top: 50px;
  height: 200px;
  padding: 40px 140px;
  background: $purple-color;
  display: flex;
  position: relative;
  overflow: hidden;
}

.bg-image {
  width: 290px;
  position: absolute;
  bottom: 0;
  mix-blend-mode: hard-light;
  right: 100px;
}

.base-functions-list {
  width: 75%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  padding-left: 80px;
}

h1 {
  width: 25%;
  font-size: 24px;
  color: $white-color;
  font-weight: 600;
}

@media (max-width: 1000px) {
  .banner-container {
    height: unset;
    padding: 30px 40px 60px 40px;
    flex-direction: column;
    border-radius: 20px;
    margin: 25px 20px 0 20px;

  }
  h1 {
    width: 100%;
    font-size: 24px;
  }
  .base-functions-list {
    width: 100%;
    justify-content: flex-start;
    padding: 0;
    margin-top: 10px;

    .function-list-item {
      justify-content: flex-start;
    }
  }
  .bg-image {
    right: -50px
  }
}
</style>