<template>
  <div class="tariffs-list">
    <tariff-item v-for="tariffItem in tariffItems" :key="tariffItem.id" :tariffItem="tariffItem"/>
  </div>
</template>

<script>
import TariffItem from "./TariffItem";

export default {
  components: {TariffItem},
  data: () => ({
    tariffItems: [
      {
        id: 1,
        type: 'Лайт',
        title: '1 канал',
        socials: [
          {
            name: 'Telegram',
            icon: require('@/assets/tariffs/social-icons/tg-icon.svg')
          },
          {
            name: 'VK',
            icon: require('@/assets/tariffs/social-icons/vk-icon.svg')
          },
          {
            name: 'Site',
            icon: require('@/assets/tariffs/social-icons/site-icon.svg')
          },
        ],
        features: [
          'Весь базовый функционал*'
        ],
        price: {
          month: 3490,
          year: 2490,
          discount: 30
        },
      },
      {
        id: 2,
        type: 'Базовый',
        title: '2 канала',
        socials: [
          {
            name: 'Telegram',
            icon: require('@/assets/tariffs/social-icons/tg-icon.svg')
          },
          {
            name: 'Instagram',
            icon: require('@/assets/tariffs/social-icons/inst-icon.svg')
          },
          {
            name: 'WhatsApp',
            icon: require('@/assets/tariffs/social-icons/wa-icon.svg')
          },
          {
            name: 'VK',
            icon: require('@/assets/tariffs/social-icons/vk-icon.svg')
          },
          {
            name: 'Facebook',
            icon: require('@/assets/tariffs/social-icons/fb-icon.svg')
          },
          {
            name: 'Site',
            icon: require('@/assets/tariffs/social-icons/site-icon.svg')
          },
        ],
        features: [
          'Весь базовый функционал*',
          'Подключение своего домена на сайт',
          'Pro-аналитика',
          'Синхронизация с CRM и POS',
          'Бесплатное QR-меню'
        ],
        price: {
          month: 4490,
          year: 3990,
          discount: 30
        },
      },
      {
        id: 3,
        title: '3 канала',
        type: 'Бизнес',
        socials: [
          {
            name: 'Telegram',
            icon: require('@/assets/tariffs/social-icons/tg-icon.svg')
          },
          {
            name: 'Instagram',
            icon: require('@/assets/tariffs/social-icons/inst-icon.svg')
          },
          {
            name: 'WhatsApp',
            icon: require('@/assets/tariffs/social-icons/wa-icon.svg')
          },
          {
            name: 'VK',
            icon: require('@/assets/tariffs/social-icons/vk-icon.svg')
          },
          {
            name: 'Facebook',
            icon: require('@/assets/tariffs/social-icons/fb-icon.svg')
          },
          {
            name: 'Site',
            icon: require('@/assets/tariffs/social-icons/site-icon.svg')
          },
        ],
        features: [
          'Весь базовый функционал*',
          'Подключение своего домена на сайт',
          'Pro-аналитика',
          'Синхронизация с CRM и POS',
          'Бесплатное QR-меню'
        ],
        price: {
          month: 6490,
          year: 5490,
          discount: 30
        },
      },
      {
        id: 4,
        type: 'Премиум',
        title: 'Все каналы',
        socials: [
          {
            name: 'Telegram',
            icon: require('@/assets/tariffs/social-icons/tg-icon.svg')
          },
          {
            name: 'Instagram',
            icon: require('@/assets/tariffs/social-icons/inst-icon.svg')
          },
          {
            name: 'WhatsApp',
            icon: require('@/assets/tariffs/social-icons/wa-icon.svg')
          },
          {
            name: 'VK',
            icon: require('@/assets/tariffs/social-icons/vk-icon.svg')
          },
          {
            name: 'Facebook',
            icon: require('@/assets/tariffs/social-icons/fb-icon.svg')
          },
          {
            name: 'Site',
            icon: require('@/assets/tariffs/social-icons/site-icon.svg')
          },
        ],
        features: [
          'Весь базовый функционал*',
          'Подключение своего домена на сайт',
          'Pro-аналитика',
          'Синхронизация с CRM и POS',
          'Бесплатное QR-меню'
        ],
        price: {
          month: 8490,
          year: 7490,
          discount: 30
        },
      }
    ]
  })
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.tariffs-list {
  margin-left: -20px;
  margin-right: -20px;
  display: flex;
  margin-top: 40px;
}


@media (max-width: 1000px) {
  .tariffs-list {
    flex-direction: column;
  }
}
</style>