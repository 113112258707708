<template>
  <div class="slider-container">
    <h1>Отзывы клиентов</h1>
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide v-for="item in items" :key="item.id">
        <img :src="item.img" alt="feedback-logo" />
        <span class="feedback-header">{{ item.name }}</span>
        <span class="feedback-description">{{ item.description }}</span>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-button swiper-button-prev" slot="button-prev">
        <svg class="arrow-icon">
          <use href="@/assets/arrow.svg#arrow-icon"></use>
        </svg>
      </div>
      <div class="swiper-button swiper-button-next" slot="button-next">
        <svg class="arrow-icon">
          <use href="@/assets/arrow.svg#arrow-icon"></use>
        </svg>
      </div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data: () => ({
    swiperOption: {
      slidesPerView: "auto",
      spaceBetween: 30,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        1250: {
          slidesPerView: "auto",
          spaceBetween: 30,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
      },
    },
    items: [
      {
        id: 1,
        name: "Дмитрий Лахмай, СЕО Kafesoft KZ",
        description:
          "С партнерами из DostavkinBot приятно иметь дело. Ребята хорошо знают свою работу и всегда отзывчивы ко всем запросам клиента. Мы просто передаем им API-ключ, а дальше они уже все делают сами. Условия сотрудничества честные и прозрачные.",
        img: require("@/assets/main-screen/slider/lakhmay.png"),
      },
      {
        id: 2,
        name: "Азат Смагулов",
        description:
          "Более года являюсь партнером DostavkinBot, и за это время мы подключили более 30 клиентов. Основная ценность сервиса - это не только автоматизация приема заказа, но и инструменты аналитики и маркетинга, которые позволяют в дальнейшем работать с накопленной клиентской базой. 90% моих клиентов продлевают подписку уже более года работы с сервисом, это вызвано не только привлекательной ценой и обширным функционалом, но и заботливой поддержкой в период подключения, интеграции с кассовыми системами и дальнейшей работы.",
        img: require("@/assets/main-screen/slider/azat.png"),
      },
      {
        id: 3,
        name: "Mazza Coffee",
        description:
          'В связи с пандемией нашему бизнесу пришлось перестраиваться на новые "рельсы" в том числе доставка еды. Среди всех вариантов мы выбрали чат бот Dostavkin исходя из критерия цена-качество. Ребята из тех поддержки очень быстро установили и помогли настроить чат бот (тех поддержка работает круглосуточно). Кроме этого разработчики-молодой коллектив Dostavkinbot прислушиваются к пожеланиям клиентов. Так например мы заявили о необходимости системы лояльности. И наша заявка была разработана и запущено в течение месяца. Мы считаем что команда Dostavkinbot целеустремленные ответственные ребята. Так держать 👍 ',
        img: require("@/assets/main-screen/slider/mazza.png"),
      },
      {
        id: 4,
        name: "Emar",
        description:
          "Искали готового бота для доставки еды и нашли сервис Dostavkinbot. Сервис помог разгрузить операторов, а также дал возможность заказывать глухонемым клиентам. До этого некоторым клиентам было неудобно звонить и они писали в Instagram, что делало невозможным всякую автоматизацию. По личному кабинету: активно пользуемся такими вкладками, как статистика, смотрим рейтинг позиций и анализируем эффективность рекламы с помощью уникальных ссылок. Также анализируем отзывы гостей.",
        img: require("@/assets/main-screen/slider/emar.png"),
      },
      {
        id: 5,
        name: "Inspiration Coffee",
        description:
          "Очень довольна Вашим сервисом, даже не знала, что можно быть довольной чьей-то работой на 100%. Ваша скорость реально восхищает, нам сделали бот буквально за 2 часа, сказать что я была приятно удивлена- ничего не сказать. Плюс очень нравится что на сайте всегда есть консультант, и он реально всегда онлайн, и всегда оперативно помогает. Также нравится что сервис довольно понятный в работе, мои сотрудники уже сами во всю работают с ним, а если что-то непонятно ваши поддержка всегда готова помочь. Наши клиенты тоже очень довольны ботом, с каждым днем все больше им пользуются. И очень круто, что мы легко их можем оцифровать, точнее бот это делает сам. Нам только остаётся делать рассылки) В общем мы от Вас в восторге) уже всем друзьям советуем Вас, успехов в Вам)",
        img: require("@/assets/main-screen/slider/inspirationcofe.png"),
      },
      {
        id: 6,
        name: "Kimchi",
        description:
          "Подключили сервис Dostavkinbot в первую очередь для удобства клиентов. Провели синхронизацию с платежной системой, и теперь клиенты легко оплачивают заказы онлайн.",
        img: require("@/assets/main-screen/slider/kimchi.png"),
      },
      {
        id: 7,
        name: "GingerSushi",
        description:
          "Основная задача стояла разгрузить операторов. Провели интеграцию с iiko. Синхронизация заняла 2 минуты. Меню обновляется регулярно и все заказы падают на терминал. Клиенты получают уведомления о смене заказа напрямую в чат-бот. Им не нужно звонить оператору и уточнить статус заказа. Таким образом мы мы все автоматизировали и улучшили качество работы с гостями просто подключим один сервис, чем остались очень довольны!",
        img: require("@/assets/main-screen/slider/gingerlogo.png"),
      },
      {
        id: 8,
        name: "KexBrands",
        description:
          "Оптимизировали с помощью одного аккаунта работу всех заведений. Понравились UTM метки, которые помогают отследить эффективность рекламы. Удобный дашборд. Отличный суперлинк для разных ссылок и каналов - легко настраивается и нравится гостям. Хороший инструмент для маркетинга. Поддержка отлично работает.",
        img: require("@/assets/main-screen/slider/kex.png"),
      },
      {
        id: 9,
        name: "Salam Bro",
        description:
          "С DostavkinBot всего лишь 1 оператор подтверждает заказ и передает его на кухню. Особенно выделил бы, что ребята без нашей просьбы сами улучшают и допиливают функции сервиса, учитывая наши пожелания и особенности.",
        img: require("@/assets/main-screen/slider/salambro.png"),
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.slider-container {
  padding: 130px 0 70px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(0deg, #eff6ff, #eff6ff);
}

h1 {
  font-size: 36px;
  font-weight: 600;
  line-height: 135%;
  text-align: center;
}

.swiper {
  width: 100%;
  padding-bottom: 40px;
}

.swiper-slide {
  margin-top: 60px;
  width: 40% !important;
  background: $white-color;
  padding: 80px 40px 60px;
  box-shadow: 0px 20px 30px #dae1f5;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;

  img {
    max-width: 84px;
    max-height: 84px;
    position: absolute;
    left: 60px;
    top: -35px;
  }
}

.feedback-header {
  font-size: 18px;
  font-weight: 600;
}

.feedback-description {
  font-family: "Gilroy Italic";
  font-size: 15px;
  font-weight: 300;
  margin-top: 10px;
}

.swiper-pagination {
  width: 50%;
  margin-left: 115px;
  position: unset;
  margin-top: 80px;
  display: flex;
  justify-content: flex-start;
}

.swiper-button {
  width: 44px;
  height: 44px;
  background: transparent;
  border: 1px solid $main-color;
  border-radius: 50%;
  top: unset;
  bottom: 25px;

  use {
    color: $main-color;
  }

  &:hover {
    background: $main-color;

    use {
      color: $white-color;
    }
  }

  &.swiper-button-prev {
    right: 170px;
    left: unset;

    svg {
      transform: rotate(180deg);
    }
  }

  &.swiper-button-next {
    right: 115px;
  }

  svg {
    width: 14px;
    height: 12px;
  }
}

@media (max-width: 1000px) {
  .slider-container {
    padding: 80px 0 100px 0;
  }
  .feedback-header {
    font-size: 15px;
    text-align: center;
  }
  .feedback-description {
    font-size: 14px;
    text-align: center;
  }
  h1 {
    font-size: 24px;
  }

  .swiper {
    padding: 0 20px 40px 20px;
  }
  .swiper-slide {
    width: 100% !important;
    height: unset;
    padding: 60px 25px 40px 25px;

    img {
      width: 120px;
      height: 120px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .swiper-pagination {
    margin-left: 0;
    margin-top: 50px;
  }
  .swiper-button {
    bottom: 20px;

    &.swiper-button-prev {
      right: 80px;
    }

    &.swiper-button-next {
      right: 20px;
    }
  }
}
</style>
